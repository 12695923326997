<div *ngIf="show" class="input-wrapper">

    <ng-container [ngSwitch]="titleType">
        <div *ngSwitchCase="'top'">

            <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>

            <ng-container [ngSwitch]="input">
                <!-- Input Field Input Cases -->
                <div *ngSwitchCase="'text'">
                    <ng-container *ngTemplateOutlet="textTemplate"></ng-container>
                </div>
                <div *ngSwitchCase="'numeric'">
                    <ng-container *ngTemplateOutlet="numericTemplate"></ng-container>
                </div>
                <div *ngSwitchCase="'date'">
                    <ng-container *ngTemplateOutlet="dateTemplate"></ng-container>
                </div>
                <div *ngSwitchCase="'datetime'">
                    <ng-container *ngTemplateOutlet="datetimeTemplate"></ng-container>
                </div>
                <div *ngSwitchCase="'dropdown'">
                    <ng-container *ngTemplateOutlet="comboboxTemplate"></ng-container>
                </div>
                <div *ngSwitchCase="'checkbox'">
                    <ng-container *ngTemplateOutlet="checkboxTemplate"></ng-container>
                </div>
                <div *ngSwitchCase="'multiselect'">
                    <ng-container *ngTemplateOutlet="multiselectTemplate"></ng-container>
                </div>
                <div *ngSwitchCase="'textarea'">
                    <ng-container *ngTemplateOutlet="textareaTemplate"></ng-container>
                </div>
                <div *ngSwitchCase="'lookup'">
                    <ng-container *ngTemplateOutlet="lookupTemplate"></ng-container>
                </div>
                <div *ngSwitchCase="'checkboxlist'">
                    <ng-container *ngTemplateOutlet="checkboxlistTemplate"></ng-container>
                </div>
                <div *ngSwitchDefault>
                    <ng-container *ngTemplateOutlet="textTemplate"></ng-container>
                </div>
            </ng-container>
        </div>

        <div *ngSwitchCase="'float'">
            <ng-container [ngSwitch]="input">

                <kendo-floatinglabel class="title Txt6" text="{{title}}">

                    <!-- Input Field Input Cases -->
                    <div *ngSwitchCase="'text'">
                        <ng-container *ngTemplateOutlet="textTemplate"></ng-container>
                    </div>
                    <div *ngSwitchCase="'numeric'">
                        <ng-container *ngTemplateOutlet="numericTemplate"></ng-container>
                    </div>
                    <div *ngSwitchCase="'date'">
                        <ng-container *ngTemplateOutlet="dateTemplate"></ng-container>
                    </div>
                    <div *ngSwitchCase="'datetime'">
                        <ng-container *ngTemplateOutlet="datetimeTemplate"></ng-container>
                    </div>
                    <div *ngSwitchCase="'dropdown'">
                        <ng-container *ngTemplateOutlet="comboboxTemplate"></ng-container>
                    </div>
                    <div *ngSwitchCase="'checkbox'">
                        <ng-container *ngTemplateOutlet="checkboxTemplate"></ng-container>
                    </div>
                    <div *ngSwitchCase="'multiselect'">
                        <ng-container *ngTemplateOutlet="multiselectTemplate"></ng-container>
                    </div>
                    <div *ngSwitchCase="'textarea'">
                        <ng-container *ngTemplateOutlet="textareaTemplate"></ng-container>
                    </div>
                    <div *ngSwitchCase="'lookup'">
                        <ng-container *ngTemplateOutlet="lookupTemplate"></ng-container>
                    </div>
                    <div *ngSwitchCase="'checkboxlist'">
                        <ng-container *ngTemplateOutlet="checkboxlistTemplate"></ng-container>
                    </div>
                    <div *ngSwitchDefault>
                        <ng-container *ngTemplateOutlet="textTemplate"></ng-container>
                    </div>
                </kendo-floatinglabel>
            </ng-container>
        </div>
    </ng-container>
  
    <ng-container *ngTemplateOutlet="errorTemplate"></ng-container>
</div>


<!-- TEMPLATES -->
<!--  Input Field Templates -->
<ng-template #textTemplate>
    <div class="common-input-control-content common-input-control-content-{{size}}">
        <kendo-textbox *ngIf="!mask"
            #textboxInput
            class="input-control k-input-inner"
            title="{{title}}"
            placeholder="{{placeholder}}"
            value="{{value}}"
            [formControl]="control"
            [disabled]="disableInput"
            [maxlength]="maxLength"
            (valueChange)="onValueChange($event)"
            (click)="onClick($event)"
            (blur)="onBlur()">
        </kendo-textbox>
        <kendo-maskedtextbox
            *ngIf="mask"
            #maskedTextboxInput
            class="input-control"
            title="{{title}}"
            placeholder="{{placeholder}}"
            value="{{value}}"
            [formControl]="control"
            [disabled]="disableInput"
            [maxlength]="maxLength"
            (valueChange)="onValueChange($event)"
            (click)="onClick($event)"
            (blur)="onBlur()"
            [mask]="mask">
        </kendo-maskedtextbox>
    </div>
</ng-template>

<ng-template #checkboxTemplate>
    <div class="common-input-control-content common-input-control-content-{{size}}">
        <kendo-textbox-container class="checkbox-container">
            <kendo-label
                class="k-checkbox-label block"
                [for]="checkboxInput"
                text="{{title}}"
            ></kendo-label>
            <input
                kendoCheckBox
                #checkboxInput
                class="input-control"
                type="checkbox"
                placeholder="{{placeholder}}"
                value="{{value}}"
                [formControl]="control"
                [disabled]="disableInput"
                (valueChange)="onValueChange($event)"
                (click)="onClick($event)"
                (blur)="onBlur()"/>
        </kendo-textbox-container>
    </div>
</ng-template>

<ng-template #numericTemplate>
    <div class="common-input-control-content common-input-control-content-{{size}}">
        <kendo-numerictextbox
            #numericTextboxInput
            class="input-control"
            [formControl]="control"
            [disabled]="disableInput"
            (valueChange)="onValueChange($event)"
            (click)="onClick($event)"
            (blur)="onBlur()"
            class="input-control"
            title="{{title}}"
            placeholder="{{placeholder}}"
            [format]="format ?? null">
        </kendo-numerictextbox>
    </div>
</ng-template>

<ng-template #dateTemplate>
    <div class="common-input-control-content common-input-control-content-{{size}}">
        <kendo-datepicker
            #datePickerInput
            class="input-control"
            title="{{title}}"
            [formControl]="control"
            [disabled]="disableInput"
            (valueChange)="onValueChange($event)"
            (click)="onClick($event)"
            (blur)="onBlur()"
            (focusout)="datePickerOnFocus($event)"
            [disabledDates]="disabledDates"
            [disabledDatesValidation]="disabledDatesValidation"
            [readOnlyInput]="readOnlyInput"
            [incompleteDateValidation]="true"
            [format]="format ?? 'MM/dd/yyyy'"
            placeholder="MM/dd/yyyy"
            [min]="commonConstants.minSmallDate"
            [max]="commonConstants.maxSmallDate">
        </kendo-datepicker>
    </div>
</ng-template>

<ng-template #datetimeTemplate>
    <div class="common-input-control-content common-input-control-content-{{size}}">
        <kendo-datepicker
            #datetimePickerInput
            class="input-control"
            [formControl]="control"
            [disabled]="disableInput"
            (valueChange)="onValueChange($event)"
            (click)="onClick($event)"
            (blur)="onBlur()"
            title="{{title}}"
            placeholder="{{placeholder}}"
            [format]="format ?? 'MM/dd/yyyy - HH:mm'"
            placeholder="MM/dd/yyyy"
            [min]="commonConstants.minSmallDateTime"
            [max]="commonConstants.maxSmallDateTime">
        </kendo-datepicker>
    </div>
</ng-template>

<ng-template #comboboxTemplate let-dataItem>
    <div class="common-input-control-content common-input-control-content-{{size}}">
        <kendo-combobox
            #comboboxInput
            class="input-control"
            [data]="options"
            [valuePrimitive]="(textField === undefined)"
            [itemDisabled]="setDisabledOptions ? setDisabledOptions : getFalse"
            [formControl]="control"
            [disabled]="disableInput"
            (valueChange)="onValueChange($event)"
            (click)="onClick($event)"
            (blur)="onBlur()"
            title="{{title}}"
            placeholder="{{placeholder}}"
            [valueField]="valueField ?? null"
            [textField]="textField ?? null">
        </kendo-combobox>
    </div>
</ng-template>

<ng-template #multiselectTemplate>
    <div class="common-input-control-content common-input-control-content-{{size}}">
        <kendo-multiselect
            #multiSelectInput
            class="input-control"
            [data]="options"
            [formControl]="control"
            [disabled]="disableInput"
            [textField]="textField"
            [valueField]="valueField"
            (valueChange)="multiSelectChangeEvent($event)"
            (blur)="onBlur()"
            title="{{title}}"
            placeholder="{{placeholder}}">
        </kendo-multiselect>
    </div>
</ng-template>

<ng-template #textareaTemplate>
    <div class="common-input-control-content">
        <kendo-textarea
            #textareaInput
            class="Txt6 common-textarea common-textarea-{{size}} input-control"
            title="{{title}}"
            [resizable]="resizable"
            [maxlength]="maxLength"
            value="{{value}}"
            [formControl]="control"
            [disabled]="disableInput"
            (valueChange)="onValueChange($event)"
            (click)="onClick($event)"
            (blur)="onBlur()">
            <kendo-textarea-suffix class="char-count">
                <div *ngIf="showCounter">
                    <span class="Txt7">{{counter}}</span>
                </div>
            </kendo-textarea-suffix>
        </kendo-textarea>
    </div>
</ng-template>

<ng-template #checkboxlistTemplate>
    <div class="common-input-control-content common-input-control-content-{{size}}">
        <kendo-treeview
            #checkboxlistInput
            [nodes]="options"
            [children]="children"
            [disabled]="disableInput"
            [hasChildren]="hasChildren"
            [textField]="textField"
            [valueField]="valueField"
            [kendoTreeViewCheckable]="checkableSettings"
            [(checkedKeys)]="checkedKeys"
            [value]="value"
            (checkedChange)="checkboxlistToValue($event)">
        </kendo-treeview>
    </div>
</ng-template>

<!-- Use Lookup to get value from another component ie popups -->
<ng-template #lookupTemplate>
    <div class="common-input-control-content common-input-control-content-{{size}}">
        <div class="lookup-input-control">
            <input
                #lookupInput
                type="text"
                class="input-control"
                title="{{title}}"
                placeholder="{{placeholder}}"
                [ngModel]="manualDisplayValue"
                (ngModelChange)="onValueChange($event)"
                value="manualDisplayValue ? manualDisplayValue : undefined"
                [formControl]="control"
                [disabled]="disableInput"
                (valueChange)="onValueChange($event)"
                (click)="onClick($event)"
                (blur)="onBlur()"
                readonly>
            <a
                kendoButton
                (click)="onClick($event)"
                class="lookup-icon">
            </a>
        </div>
    </div>
</ng-template>

<!-- COMMON TEMPLATES -->
<!-- Title Template -->
<ng-template #titleTemplate>
    <div *ngIf="title" class="title-content title-content-{{titleType}}">
        <span class="title Txt6">{{ title }}</span>
        <ng-container *ngIf="checkRequired()"><span class="title Txt6 d-inline-block req-star">*</span></ng-container>
        <CDMS-common-info-slideout-control
            *ngIf="infoSlideout"
            class="title-info"
            title="{{infoSlideoutTitle}}"
            text="{{infoSlideoutText}}"
            iconSize="small">
        </CDMS-common-info-slideout-control>
    </div>
</ng-template>

<!-- Error Template -->
<ng-template #errorTemplate>
    <span class="error Txt7">
        <ng-container
            *ngIf="control?.errors
            && control?.invalid
            && control?.touched">
            <ng-container 
            *ngFor="let error of control.errors | keyvalue">
                {{errorMessage[error.key]}}
            </ng-container>
        </ng-container>
    </span>
</ng-template>