<div
    *ngIf="show"
    class="status-container-wrapper">
    <div>
        <table class="status-table">
            <thead>
                <tr>
                    <th class="status-table-label"></th>
                    <th class="status-table-label">Approve</th>
                    <th class="status-table-label">Reject</th>
                </tr>
            </thead>
            <tr>
                <td class="status-table-label">All</td>
                <td>
                    <input
                        #aa
                        title="Approve All"
                        type="checkbox"
                        kendoCheckBox
                        class="approved"
                        [disabled]="(statusData.photo === null || statusData.photo === undefined)
                        && (statusData.about === null || statusData.about === undefined)
                        && (statusData.snippet === null || statusData.snippet === undefined)"
                        [checked]="statusData.all === true"
                        (click)="setStatus('all', true)"
                        [name]="'all'"/>
                </td>
                <td>
                    <input
                        #ar
                        title="Reject All"
                        type="checkbox"
                        kendoCheckBox
                        class="rejected"
                        [disabled]="(statusData.photo === null || statusData.photo === undefined)
                        && (statusData.about === null || statusData.about === undefined)
                        && (statusData.snippet === null || statusData.snippet === undefined)"
                        [checked]="statusData.all === false"
                        (click)="setStatus('all', false)"
                        [name]="'all'"/>
                </td>
            </tr>
            <tr *ngIf="statusData.photo !== null && statusData.photo !== undefined">
                <td class="status-table-label">Photo</td>
                <td>
                    <input
                        title="Approve Photo"
                        type="checkbox"
                        kendoCheckBox
                        class="approved"
                        [checked]="statusData.photo === true"
                        (click)="setStatus('photo', true)"
                        [name]="'photo'"/>
                </td>
                <td>
                    <input
                        title="Reject Photo"
                        type="checkbox"
                        kendoCheckBox
                        class="rejected"
                        [checked]="statusData.photo === false"
                        (click)="setStatus('photo', false)"
                        [name]="'photo'"/>
                </td>
            </tr>
            <tr *ngIf="statusData.about !== null && statusData.about !== undefined">
                <td class="status-table-label">About</td>
                <td>
                    <input
                        title="Approve About"
                        type="checkbox"
                        kendoCheckBox
                        class="approved"
                        [checked]="statusData.about === true"
                        (click)="setStatus('about', true)"
                        [name]="'about'"/>
                </td>
                <td>
                    <input
                        title="Reject About"
                        type="checkbox"
                        kendoCheckBox
                        class="rejected"
                        [checked]="statusData.about === false"
                        (click)="setStatus('about', false)"
                        [name]="'about'"/>
                </td>
            </tr>
            <tr *ngIf="statusData.snippet !== null && statusData.snippet !== undefined">
                <td class="status-table-label">Snippet</td>
                <td>
                    <input
                        title="Approve Snippet"
                        type="checkbox"
                        kendoCheckBox
                        class="approved"
                        [checked]="statusData.snippet === true"
                        (click)="setStatus('snippet', true)"
                        [name]="'snippet'"/>
                </td>
                <td>
                    <input
                        title="Reject Snippet"
                        type="checkbox"
                        kendoCheckBox
                        class="rejected"
                        [checked]="statusData.snippet === false"
                        (click)="setStatus('snippet', false)"
                        [name]="'snippet'"/>
                </td>
            </tr>
        </table>

    </div>
</div>
