import { AppConstants } from '../../constants/app-constants';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nulldate',
  pure: false
})
export class NullDate implements PipeTransform {

  constructor() { }

  transform(date: any): any {
    if (date === '01/01/0001') {
      return '';
    }
    return date;
  }

}
