import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { ProviderDirectoryFunctionService}
  from 'src/app/services/providerdirectoryapi/providerdirectoryfunctionapi.services';
import { emailTypes } from 'src/app/services/mock-data';


@Injectable({
  providedIn: 'root',
})

export class EmailTypesService implements OnDestroy {
  private _entity: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(
    []
  );

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private searchService: ProviderDirectoryFunctionService,
  ) {}

  async search() {
    const data: string[] = emailTypes; // similate http call
    /*
    this.searchService.getEmailTypes()
      .pipe(
          take(1),
          takeUntil(this._destroying$)
      )
      .subscribe((res) => {
        if (res) { this._entity.next(res); }
    });
    */
    this._entity.next(data);
  }

  public fetchData(): Observable<string[]> {
    if ( this._entity.getValue.length === 0 ) {
      this.search();
    }
    return this._entity;
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
