<div class="page-wrapper">
    <div class="column">
        <form [formGroup]="searchForm">

            <!-- SEARCH FIELDSS -->
            <div class="row search-fields">
                <div class="search-field">
                    <kendo-formfield>
                        <CDMS-common-input-control
                            title="First Name"
                            [control]="this.searchForm.get('firstName')"
                            ngDefaultControl
                            formControlName="firstName"
                            [input]="'text'"
                            [size]="'medium'"
                            [value]="searchData.firstName">
                        </CDMS-common-input-control>
                    </kendo-formfield>
                </div>
                <div class="search-field">
                    <kendo-formfield>
                        <CDMS-common-input-control
                            title="Last Initial"
                            [control]="this.searchForm.get('lastName')"
                            ngDefaultControl
                            formControlName="lastName"
                            [input]="'text'"
                            [size]="'medium'"
                            [value]="searchData.lastName">
                        </CDMS-common-input-control>
                    </kendo-formfield>
                </div>
                <div class="search-field">
                    <kendo-formfield>
                        <CDMS-common-input-control
                            title="ID"
                            [control]="this.searchForm.get('id')"
                            ngDefaultControl
                            formControlName="id"
                            [input]="'text'"
                            [size]="'medium'"
                            [value]="searchData.id">
                        </CDMS-common-input-control>
                    </kendo-formfield>
                </div>
                <div class="search-field">
                    <kendo-formfield>
                        <CDMS-common-input-control
                            title="Email"
                            [control]="this.searchForm.get('email')"
                            ngDefaultControl
                            formControlName="email"
                            [input]="'text'"
                            [size]="'medium'"
                            [value]="searchData.email">
                        </CDMS-common-input-control>
                    </kendo-formfield>
                </div>
            </div>

            <!-- SEARCH BUTTONS -->
            <div class="row search-buttons">
                <div class="search-field">
                    <CDMS-common-button-control
                        title="Clear"
                        [button]="'standard'"
                        [size]="'small'"
                        [type]="'secondary'"
                        (clickEvent)="clear()">
                    </CDMS-common-button-control>
                </div>
                <div>
                    <CDMS-common-button-control
                        title="Search"
                        [button]="'standard'"
                        [size]="'small'"
                        [type]="'primary'"
                        (clickEvent)="search()">
                    </CDMS-common-button-control>
                </div>
            </div>
        </form>

        <!-- GRID -->
        <div class="row grid">
            <CDMS-common-grid-control
                [title]="'Admin Accounts'"
                [id]="'335c2c5a-ecf7-486f-8d39-4244c876cdd6'"
                [kendoGridBindingData]="gridData.data"
                [columns]="columns"
                [scrollable]="'scrollable'"
                [loading]="gridLoading"
                [sort]="state.sort"
                [filter]="state.filter"
                [skip]="state.skip"
                [pageable]="true"
                [showCheckBoxCol]="true"
                [showCheckBoxColSelectAll]="true"
                [selectable]="true"
                [modalEdit] = "true"
                [modalCreate] = "true"
                [toolbarDelete] = "true"
                (createEvent) = "save($event)"
                (updateEvent) = "update($event)"
                (deleteEvent) = "delete($event)">
            </CDMS-common-grid-control>
        </div>
    </div>
</div>
