export class RegularExpressions {
    public static roleName = '^[a-zA-Z0-9 \\-:@_|~]+$';
    public static alphaNumeric = '^[a-zA-Z0-9]+$';
    public static alphaNumericWithHyphen = '^[a-zA-Z0-9\\-]+$';
    public static time = '^(?:0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$';
    public static deductionId = '^[A-Z,a-z,0-9,#,-]*$';
    public static alphabets = '^[a-zA-Z]+$';
    public static einNumeric = /\d{9}/;
    public static ssn = /^(?!000|666)[0-8][0-9]{2}(?!00)[0-9]{2}(?!0000)[0-9]{4}$/;
    public static formattedSsn = /^(?!000|666)[0-8][0-9]{2}([-]?)(?!00)[0-9]{2}([-]?)(?!0000)[0-9]{4}$/;
    public static formattedItin = /^(9\d{2})([-]?)((5[0-9]|6[0-5]|7[0-9]|8[0-8]|9[0-2]|9[4-9]))([-]?)(\d{4})$/;
    public static email = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    public static alphaNumericWithSpace = /^[A-Za-z0-9- ]+$/;
    public static alphaNumericNoSpace = /^[a-zA-Z0-9]+$/;
    public static zipcode = /^[0-9]{5}(?:-[0-9]{4})?$/;
    public static nameWithSpace = /^[\sa-z\-A-Z][a-z\-A-Z\s]*$/;
    public static nameWithDiacritics = /^(?!\s+$)[A-Za-zÀ-žÂ-ẑçġö .`'-]*$/;
    public static suffixWithSpace = /^[\sa-z\.\-A-Z][a-z\.\-A-Z\s]*$/;
}
