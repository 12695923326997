import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { UploadEvent } from '@progress/kendo-angular-upload';
import { ValidationFunctions } from '../../../common-components/utilities/common-validation-functions';
import { AppbarService } from '../../../components/appbar/appbar-service';
import { StateService } from '../../../controllers/reference-data/states.controller';
import { CertificatesService } from '../../../controllers/reference-data/certificates.controller';
import { AttendantProfileService } from '../../../controllers/attendant/attendant-profile.controller';
import { ServicesService } from '../../../controllers/reference-data/services.controller';
import { WorkPreferencesService } from '../../../controllers/reference-data/work-preferences.controller';
import { LanguagesService } from '../../../controllers/reference-data/languages.controller';
import { AdminApprovalService } from '../../../controllers/admin/admin-approval.controller';
import { UserService } from '../../../controllers/user/user.controller';
import { FileUploadService } from '../../../controllers/file-upload/file-upload.controller';
import { radiusOptions, choiceOptions, dateOptions } from '../../../constants/app-constants';
import { StateAbbr } from '../../../constants/app-types';
import { Upload } from '../../../models/upload/upload.model';
import { Service, Certification, Language, WorkPreference,
  ReviewRequest, FileParameter, Schedule, Attendant } from '../../../services/providerdirectoryapi/providerdirectoryfunctionapi.services';
import MsalServiceWrapper from 'src/app/services/msal.wrapper.service';
import { MsalService } from '@azure/msal-angular';
import { ConfigService } from 'src/app/services/config.service';


@Component({
  selector: 'app-attendant-profile-edit',
  templateUrl: 'attendant-profile-edit.page.html',
  styleUrls: ['attendant-profile-edit.page.scss'],
})
export class AttendantProfileEditPage implements OnInit, OnDestroy {

  public attendantData: Attendant;
  public originalAttendantData: Attendant;
  public attendantDataForm: FormGroup;
  public status: ReviewRequest[];
  public statusPic: ReviewRequest;
  public statusAboutMe: ReviewRequest;
  public statusSnippet: ReviewRequest;
  public travelOptions = radiusOptions;
  public choiceOptions: Array<{ text: string; value: boolean }> = choiceOptions;
  public dateOptions: string[] = dateOptions;
  public aboutusOptions: Array<{ text: string; value: string }>;
  public stateOptions: StateAbbr[];
  public serviceOptions: Service[];
  public certOptions: Certification[];
  public languageOptions: Language[];
  public workPrefOptions: WorkPreference[];
  public showChangesDialog = false;
  public showDeleteDialog = false;
  public showPasswordDialog = false;
  public fileLoad = false;
  public loadProfile = false;
  public loadReview = false;
  public floatOffset = '150px';
  public colorado = this.configService.readConfig().colorado;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private appbarService: AppbarService,
    private stateService: StateService,
    private certificateService: CertificatesService,
    private servicesService: ServicesService,
    private workPreferencesService: WorkPreferencesService,
    private languagesService: LanguagesService,
    private attendantProfileService: AttendantProfileService,
    private adminApprovalService: AdminApprovalService,
    private userService: UserService,
    private fileUploadService: FileUploadService,
    private router: Router,
    private msalService: MsalServiceWrapper,
    private authService: MsalService,
    private configService: ConfigService,
  ) {
      // Reference Data
      this.stateService.fetchData()
      .pipe(
        takeUntil(this._destroying$)
      )
      .subscribe((data) => {
        if (data && data.length> 0) {
          this.stateOptions = data;
        }
      });

      this.certificateService.fetchData()
      .pipe(
        takeUntil(this._destroying$)
      )
      .subscribe((data) => {
        if (data && data.length> 0) {
          this.certOptions = data;
        }
      });

      this.servicesService.fetchData()
      .pipe(
        takeUntil(this._destroying$)
      )
      .subscribe((data) => {
        if (data && data.length> 0) {
          this.serviceOptions = data;
        }
      });

      this.workPreferencesService.fetchData()
      .pipe(
        takeUntil(this._destroying$)
      )
      .subscribe((data) => {
        if (data && data.length> 0) {
          this.workPrefOptions = data;
        }
      });

      this.languagesService.fetchData()
      .pipe(
        takeUntil(this._destroying$)
      )
      .subscribe((data) => {
        if (data && data.length> 0) {
          this.languageOptions = data;
        }
      });

      //
      this.appbarService.getAdminTrigger()
      .pipe(
        takeUntil(this._destroying$)
      )
      .subscribe((func) => {
        if (func) {
          switch (func) {
            case 'refresh':
              this.languageRefresh();
              break;
          }
        }
      });

      // Attendant Data
      this.attendantProfileService.fetchData(true)
      .pipe(
        takeUntil(this._destroying$)
      )
      .subscribe((data) => {
        if (data !== undefined) {
          this.attendantData = data;
          if (this.checkData()) {
            this.setForm();
            this.adminApprovalService.getReviewRequest(this.userService.getIdNow());
          }
        }
      });

      this.adminApprovalService.getRequestsByID(this.userService.getIdNow(), false)
      .pipe(
        takeUntil(this._destroying$)
      )
      .subscribe((data) => {
        if (data !== undefined && data !== null) {
          this.status = data;
          if (data.length > 0) {
            data.forEach(x => {
              switch (x.type) {
                case 0:
                  this.statusPic = x;
                  if (this.attendantData) {
                    this.attendantData.photoUrl = x.metadata;
                  }
                  break;
                case 1:
                  break;
                case 2:
                  this.statusAboutMe = x;
                  if (this.attendantData) {
                    this.attendantData.about = x.metadata;
                  }
                  break;
                case 3:
                  this.statusSnippet = x;
                  if (this.attendantData) {
                    this.attendantData.snippet = x.metadata;
                  }
                  break;
              }
            });
            if (this.checkData()) {
              this.setForm();
            }
          }
        }
      });

      this.fileUploadService.getEntity()
      .pipe(
        takeUntil(this._destroying$)
      )
      .subscribe((data) => {
        if (data !== undefined) {
          if (this.attendantData) {
            this.attendantData.photoUrl = data.value;
            this.statusPic = new ReviewRequest();
            this.statusPic.status = 0;
          }
        }
      });

      //
      this.fileUploadService.getLoad()
      .pipe(
        takeUntil(this._destroying$)
      )
      .subscribe((data) => {
        if (data !== undefined) {
          this.fileLoad = data;
        }
      });

      addEventListener('resize', () => {
        this.setFloat();
      });
  }

  ngOnInit(): void {
    this.setPage();
    this.setFloat();
  }

  ionViewWillEnter(): void {
    this.setPage();
    this.setFloat();
  }

  public checkData() {
    if (this.stateOptions
      && this.certOptions
      && this.serviceOptions
      && this.workPrefOptions
      && this.languageOptions
      && this.attendantData) {
        return true;
      }
    return false;
  }

  public setPage() {
    this.appbarService.setPage('attendant-edit');
  }

  public setFloat() {
    const viewportWidth = window.innerWidth;
    if (viewportWidth >= 1000) { this.floatOffset = '130px'; }
    if (viewportWidth < 1000) { this.floatOffset = '125px'; }
    if (viewportWidth < 800) { this.floatOffset = '120px'; }
    if (viewportWidth < 600) { this.floatOffset = '115px'; }
    if (viewportWidth < 400) { this.floatOffset = '110px'; }
  }

  public setForm() {
    this.attendantDataForm = new FormGroup({
      firstName: new FormControl(this.attendantData.firstName,
        [Validators.required, ValidationFunctions.nameOnlyAlphaWithSpacesValidation]),
      lastName: new FormControl(this.attendantData.lastName,
        [Validators.required, ValidationFunctions.nameOnlyAlphaWithSpacesValidation]),
      email: new FormControl(this.attendantData.email, [Validators.required, ValidationFunctions.emailValidation]),
      dob: new FormControl(this.attendantData?.dob?.getFullYear() < 1901
        ? new Date() : this.attendantData.dob,
        [Validators.required, ValidationFunctions.validateSmallDate, ValidationFunctions.validatePastDate]),
      zip: new FormControl(this.attendantData.zip,
        [Validators.required, Validators.maxLength(5), ValidationFunctions.zipcodeValidation]),
      travelDistance: new FormControl(this.attendantData.travelDistance, [Validators.required]),
      state: new FormControl(this.attendantData.state, [Validators.required]),
      isActive: new FormControl(this.attendantData.isActive, []),
      about: new FormControl(this.attendantData.about, []),
      snippet: new FormControl(this.attendantData.snippet, []),
      experience: new FormControl(this.attendantData.experience, [ValidationFunctions.numberGreaterThanOrEqualtoZero]),
      prefHireDate: new FormControl(this.attendantData?.prefHireDate?.getFullYear() < 1901
        ? new Date() : this.attendantData.prefHireDate, [ValidationFunctions.validateSmallDate]),
      weeklyHours: new FormControl(this.attendantData.weeklyHours, [ValidationFunctions.numberGreaterThanOrEqualtoZero]),
      certifications: new FormControl(this.attendantData.certifications, []),
      services: new FormControl(this.attendantData.services, []),
      workPreferences: new FormControl(this.attendantData.workPreferences, []),
      languages: new FormControl(this.attendantData.languages, []),
      schedules: new FormControl(this.attendantData.schedules, []),
      dateQuickSelect: new FormControl('', []),
    });
    this.originalAttendantData = this.deepCopy(this.attendantData) as Attendant;
  }

  public deepCopy(obj) {
    let copy;
    if (null == obj || 'object' != typeof obj) {
      return obj;
    }
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }
    if (obj instanceof Array) {
        copy = [];
        for (let i = 0, len = obj.length; i < len; i++) {
            copy[i] = this.deepCopy(obj[i]);
        }
        return copy;
    }
    if (obj instanceof Object) {
        copy = {};
        for (const attr in obj) {
            if (obj.hasOwnProperty(attr)) {
              copy[attr] = this.deepCopy(obj[attr]);
            }
        }
        return copy;
    }
}

  public cancel() {
    this.router.navigate(['/Attendant']);
  }

  public save() {
    if (this.attendantDataForm.valid) {
      this.showChangesModal();
    }
  }

  public showChangesModal() {
    this.showChangesDialog = true;
  }

  public closeChangesModal(event: string) {
    this.showChangesDialog = false;
    if (event === 'yes') {
      this.formToEntity();

      const f =
        this.originalAttendantData.workPreferences.filter(x => this.attendantData.workPreferences.find( y => y.id === x.id) === undefined);
      f.forEach((x: WorkPreference) => {
        if (!x.isDeleted) {
          x.isDeleted = true;
          this.attendantData.workPreferences.push(new WorkPreference(x));
        }
      });
      const g =
        this.originalAttendantData.certifications.filter(x => this.attendantData.certifications.find( y => y.id === x.id) === undefined);
      g.forEach(x => {
        if (!x.isDeleted) {
            x.isDeleted = true;
            this.attendantData.certifications.push(new Certification(x));
        }
      });
      const h = this.originalAttendantData.services.filter(x => this.attendantData.services.find( y => y.id === x.id) === undefined);
      h.forEach(x => {
        if (!x.isDeleted) {
          x.isDeleted = true;
          this.attendantData.services.push(new Service(x));
        }
      });
      const i = this.originalAttendantData.schedules.filter(x => this.attendantData.schedules.find( y => y.id === x.id) === undefined);
      i.forEach((x: Schedule) => {
        if (!x.isDeleted) {
          const day: Schedule = new Schedule();
          day.id = x.id;
          day.isDeleted = true;
          day.dayOfWeek = x.dayOfWeek;
          this.attendantData.schedules.push(day);
        }
      });
      const j = this.originalAttendantData.languages.filter(x => this.attendantData.languages.find( y => y.id === x.id) === undefined);
      j.forEach(x => {
        if (!x.isDeleted) {
          x.isDeleted = true;
          this.attendantData.languages.push(new Language(x));
        }
      });
      if(typeof(this.attendantData.dob) === 'string'){
        this.attendantData.dob = new Date(this.attendantData.dob);
      }
      this.checkReviewRequestsOnSave();
      this.attendantData.isCO = this.colorado;
      this.attendantProfileService.update(this.attendantData, true);
      this.router.navigate(['/Attendant']);
    }
  }

  private checkReviewRequestsOnSave() {
    if (!this.attendantDataForm.controls['about'].dirty && this.statusAboutMe) {
      this.attendantData.about = this.statusAboutMe.metadata;
    }
    if (!this.attendantDataForm.controls['snippet'].dirty && this.statusSnippet) {
      this.attendantData.snippet = this.statusSnippet.metadata;
    }
    if (this.attendantData.photoUrl !== this.originalAttendantData.photoUrl
      || this.attendantData.snippet !== null
      || this.attendantData.about !== null) {
        this.attendantData.status = 'Pending';
      }
  }

  public formToEntity() {
    Object.keys(this.attendantDataForm.controls).forEach(key => {
      if (this.attendantDataForm.controls[key].value?.value) {
        this.attendantData[key] = this.attendantDataForm.controls[key].value.value;
      } else {
        this.attendantData[key] = this.attendantDataForm.controls[key].value;
      }
    });
  }

  public showPasswordModal() {
    this.showPasswordDialog = true;
  }

  public closePasswordModal(event: string) {
    this.showPasswordDialog = false;
  }

  public showDeleteModal() {
    this.showDeleteDialog = true;
  }

  public closeDeleteModal(event: string) {
    this.showDeleteDialog = false;
    if (event === 'yes') {
      this.router.navigate(['/Home']);
    }
  }

  public goFlow(customPolicy) {
    this.msalService.initiateFlow(customPolicy);
    this.authService.logout();
  }

  public setSchedule(schedule: any) {
    this.attendantDataForm.controls['schedules'].setValue(schedule);
  }

  public profilePictureUpload(event: UploadEvent){
    const upload = new Upload();
    const fileParameter: FileParameter = {
      data: event.files[0].rawFile,
      fileName: event.files[0].name
    };
    upload.requestType = 0;
    upload.userId = this.userService.getIdNow();
    upload.item = fileParameter;
    this.fileUploadService.upload(upload);
    this.updateProfileStatus();
  }

  public updateProfileStatus() {
    this.attendantData.status = 'Pending';
    this.originalAttendantData.status = 'Pending';
    if (this.originalAttendantData.zip !== 0) {
      this.attendantProfileService.update(this.originalAttendantData, false);
    }
  }

  public dateQuickSelect(event) {
    const endDate = new Date();

    switch (event) {
      case 'Today':
      case 'Este Dia':
        this.attendantDataForm.controls['prefHireDate'].setValue(endDate);
        break;
      case 'One Week':
      case 'Una Semana':
        this.attendantDataForm.controls['prefHireDate']
        .setValue(new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 7));
        break;
      case 'Two Weeks':
      case 'Dos Semanas':
        this.attendantDataForm.controls['prefHireDate']
        .setValue(new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 14));
        break;
    }
  }

  private languageRefresh() {
    window.location.reload();
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
