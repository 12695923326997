import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { Service, ProviderDirectoryFunctionService}
  from 'src/app/services/providerdirectoryapi/providerdirectoryfunctionapi.services';
import { NotificationSystemService, INotification } from 'src/app/common-components/controllers/common-notification-system.controller';


@Injectable({
  providedIn: 'root',
})

export class ServicesService implements OnDestroy {
  private _entity: BehaviorSubject<Service[]> = new BehaviorSubject<Service[]>(
    []
  );
  private _load: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private searchService: ProviderDirectoryFunctionService,
    private notificationSystemService: NotificationSystemService,
  ) {}

  async search() {
    this._load.next(true);
    this.searchService.getAllServices()
      .pipe(
          take(1),
          takeUntil(this._destroying$)
      )
      .subscribe({
        next: (res) => {
          if (res) { this._entity.next(res.data); }
          this._load.next(false);
        },
        error: error => {
          this._load.next(false);
          if (error.message) {
            const notification: INotification = {type: 'error', content: error.message};
            this.notificationSystemService.setNotification(notification);
          }
        }
    });
  }

  public fetchData(): Observable<Service[]> {
    if ( this._entity.getValue.length === 0 ) {
      this.search();
    }
    return this._entity;
  }

  public getLoad(): Observable<boolean> {
    return this._load;
  }

  public clear(): void {
    this._entity.next(null);
  }

  public clearEntity(): void {
    this._entity.next(null);
  }

  public clearLoad(): void {
    this._load.next(null);
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
