import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { FloatingLabelModule } from '@progress/kendo-angular-label';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

import { CommonButtonControlComponent } from './common-button/common-button-control.component';
import { CommonConfirmationModalControlComponent } from './common-confirmation-modal/common-confirmation-modal-control.component';
import { CommonInputControlComponent } from './common-input/common-input-control.component';
import { CommonGridControlComponent } from './common-grid/common-grid-control.component';
import { CommonGridModalControlComponent } from './common-grid-modal/common-grid-modal-control.component';
import { CommonInfoSlideoutControlComponent } from './common-info-slideout/common-info-slideout-control.component';
import { CommonSmartdateControlComponent } from './common-smartdate/common-smartdate-control.component';
import { CommonUploadControlComponent } from './common-upload/common-upload-control.component';
import { CommonLoadIndicatorControl } from './common-loader/common-load-indicator-control.component';
import { NullDate } from './pipes/nulldate.pipe';


@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        BrowserModule,
        DialogsModule,
        TooltipModule,
        GridModule,
        ExcelModule,
        ButtonsModule,
        InputsModule,
        LayoutModule,
        DropDownsModule,
        DateInputsModule,
        UploadsModule,
        TreeViewModule,
        FloatingLabelModule,
        IndicatorsModule,
    ],
    declarations: [
        CommonButtonControlComponent,
        CommonConfirmationModalControlComponent,
        CommonInputControlComponent,
        CommonGridControlComponent,
        CommonGridModalControlComponent,
        CommonInfoSlideoutControlComponent,
        CommonSmartdateControlComponent,
        CommonUploadControlComponent,
        CommonLoadIndicatorControl,
        NullDate,
    ],
    exports: [
        CommonButtonControlComponent,
        CommonConfirmationModalControlComponent,
        CommonInputControlComponent,
        CommonGridControlComponent,
        CommonGridModalControlComponent,
        CommonInfoSlideoutControlComponent,
        CommonSmartdateControlComponent,
        CommonUploadControlComponent,
        CommonLoadIndicatorControl,
        NullDate,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class CommonComponentsModule {}
