import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { RecaptchaErrorParameters, RecaptchaComponent } from 'ng-recaptcha';

// https://www.npmjs.com/package/ng-recaptcha#api-options

@Component({
    selector: 'CDMS-captcha-control',
    templateUrl: 'captcha-control.component.html',
    styleUrls: ['./captcha-control.component.scss'],
})
export class CaptchaControlComponent implements OnInit {

    @Input() show = true;
    @Input() size: 'normal' | 'compact' | 'invisible' = 'normal';

    @Output() resolved = new EventEmitter();
    @Output() errorEvent = new EventEmitter();

    @ViewChild('recaptcha', { static: true }) recaptcha: RecaptchaComponent;

    constructor(
    ) { }

    ngOnInit() {
    }

    public onResolved(captchaResponse: string): void {
        this.resolved.emit();
    }

    public onError(errorDetails: RecaptchaErrorParameters): void {
        this.errorEvent.emit(errorDetails);
    }

    public reset(): void {
        this.recaptcha.reset();
    }

    public execute(): void {
        this.recaptcha.execute();
    }
}
