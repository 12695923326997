import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {Attendant, AttendantRequest, ProviderDirectoryFunctionService}
  from 'src/app/services/providerdirectoryapi/providerdirectoryfunctionapi.services';
import { NotificationSystemService, INotification } from 'src/app/common-components/controllers/common-notification-system.controller';


@Injectable({
  providedIn: 'root',
})

export class AttendantProfileService implements OnDestroy {
  private _entity: BehaviorSubject<Attendant> = new BehaviorSubject<Attendant>(
    undefined
  );
  private _parameters: BehaviorSubject<string> = new BehaviorSubject<string>(
    undefined
  );
  private _success: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    undefined
  );
  private _load: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private readonly _destroying$ = new Subject<void>();
  private _token = '7b49e2d5-5bb4-419d-a80e-af5bda36ed6b';

  constructor(
    private notificationSystemService: NotificationSystemService,
    private providerDirectoryFunctionService: ProviderDirectoryFunctionService,
  ) {}

  private localSave() {
    sessionStorage.setItem(this._token, this._parameters.value);
  }

  private localLoad() {
    const check = sessionStorage.getItem(this._token);
    if (check !== null) {
      this._parameters.next(sessionStorage.getItem(this._token));
      return true;
    }
    return false;
  }

  async search(data?: string) {
    if (data !== undefined) {
      this._parameters.next(data);
    } else if (this._parameters.getValue()) {
      data = this._parameters.getValue();
    } else {
      if (!this.localLoad()) {
        return;
      }
      data = this._parameters.getValue();
    }
    Object.keys(data).forEach(key => {
      if (data[key] === null) {
        delete data[key];
      }
    });
    this._load.next(true);
    this.providerDirectoryFunctionService.getAttendantById(data)
    .pipe(
        take(1),
        takeUntil(this._destroying$)
    )
    .subscribe({
      next: (res) => {
        if (res) {
          if (res.data.userId.length > 0) {
            const attendant: Attendant = new Attendant();
            for(const k in res.data) {
              if (true) {
                attendant[k]=res.data[k];
              }
            }
            this._entity.next(attendant);
          }
        }
      },
      error: error => {
        if (error.message) {
          const notification: INotification = {type: 'error', content: error.message};
          this.notificationSystemService.setNotification(notification);
        }
      },
      complete: () => {
        this._load.next(false);
      },
    });
  }

  public update(data: Attendant, full = true): void {
    const attendant: AttendantRequest = new AttendantRequest(data);
    attendant.fullUpdate = full;
    Object.keys(attendant).forEach(key => {
      if (attendant[key] === null) {
        delete attendant[key];
      }
    });
    this._load.next(true);
    this.providerDirectoryFunctionService.updateAttendant(attendant)
    .pipe(
      take(1),
      takeUntil(this._destroying$)
    )
    .subscribe({
      next: (res) => {
        if (res) {
          if (!res.isSuccess) {
            const notification: INotification = {type: 'error', content: res.message};
            this.notificationSystemService.setNotification(notification);
          } else {
            this._entity.next(data);
          }
        }
      },
      error: error => {
        if (error.message) {
          const notification: INotification = {type: 'error', content: error.message};
          this.notificationSystemService.setNotification(notification);
        }
      },
      complete: () => {
        this._load.next(false);
      },
    });
  }

  public affirm(data: string): void {
    this._load.next(true);
    this.providerDirectoryFunctionService.affirm(data)
    .pipe(
      take(1),
      takeUntil(this._destroying$)
    )
    .subscribe({
      next: (res) => {
        if (res) {
          this._success.next(res.isSuccess);
          if (!res.isSuccess) {
            const notification: INotification = {type: 'error', content: res.message};
            this.notificationSystemService.setNotification(notification);
          } else {
            const notification: INotification = {type: 'success', content: 'Reaffirmation Successful'};
            this.notificationSystemService.setNotification(notification);
          }
        }
      },
      error: error => {
        if (error.message) {
          const notification: INotification = {type: 'error', content: error.message};
          this.notificationSystemService.setNotification(notification);
        }
      },
      complete: () => {
        this._load.next(false);
      },
    });
  }

  public fetchData(override = false): Observable<Attendant> {
    if (override) {
      this.search();
    }
    return this._entity;
  }

  public getSearchParameters(): Observable<string> {
    return this._parameters;
  }

  public setSearchParameters(data: string) {
    this._parameters.next(data);
    this.localSave();
  }

  public getLoad(): Observable<boolean> {
    return this._load;
  }

  public getSuccess(): Observable<boolean> {
    return this._success;
  }

  public clear(): void {
    this._entity.next(null);
    this._parameters.next(null);
  }

  public clearEntity(): void {
    this._entity.next(null);
  }

  public clearParamters(): void {
    this._parameters.next(null);
  }

  public clearLoad(): void {
    this._load.next(null);
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
