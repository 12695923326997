<div *ngIf="show" class="button-wrapper">

    <ng-container [ngSwitch]="button">

        <!-- Button Type Cases -->
        <div *ngSwitchCase="'standard'">
            <ng-container *ngTemplateOutlet="standardTemplate"></ng-container>
        </div>
        <div *ngSwitchCase="'icon'">
            <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
        </div>
        <div *ngSwitchCase="'text'">
            <ng-container *ngTemplateOutlet="textTemplate"></ng-container>
        </div>
        <div *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="standardTemplate"></ng-container>
        </div>

        <!--  Button Templates -->
        <ng-template #standardTemplate>
            <div *ngIf="tooltip" kendoTooltip [showAfter]="500">
                <button 
                    *ngIf="!float"
                    kendoButton
                    #standardButton
                    class="Txt6 common-button common-button-{{size}} common-button-{{type}} common-button-{{type}}-{{disabled}}"
                    [title]="title ?? 'Button'"
                    [disabled]="disabled"
                    type="button"
                    (click)="click($event)">
                    {{title}}
                </button>
                <kendo-floatingactionbutton
                    *ngIf="float"
                    #standardFloatButton
                    [offset]="offset"
                    [align]="align"
                    [positionMode]="positionMode"
                    class="Txt6 common-button common-button-{{size}} common-button-{{type}} common-button-{{type}}-{{disabled}}"
                    [title]="title ?? 'Button'"
                    [disabled]="disabled"
                    [text]="title ?? 'Button'"
                    (click)="click($event)">
                    {{title}}
                </kendo-floatingactionbutton>
            </div>
            <div *ngIf="!tooltip">
                <button 
                    *ngIf="!float"
                    kendoButton
                    #standardButton
                    class="Txt6 common-button common-button-{{size}} common-button-{{type}} common-button-{{type}}-{{disabled}}"
                    [title]="title ?? 'Button'"
                    [disabled]="disabled"
                    type="button"
                    (click)="click($event)">
                    {{title}}
                </button>
                <kendo-floatingactionbutton
                    *ngIf="float"
                    #standardFloatButton
                    [offset]="offset"
                    [align]="align"
                    [positionMode]="positionMode"
                    class="Txt6 common-button common-button-{{size}} common-button-{{type}} common-button-{{type}}-{{disabled}}"
                    [title]="title ?? 'Button'"
                    [disabled]="disabled"
                    [text]="title ?? 'Button'"
                    (click)="click($event)">
                    {{title}}
                </kendo-floatingactionbutton>
            </div>
        </ng-template>

        <ng-template #iconTemplate>
            <div *ngIf="tooltip" kendoTooltip [showAfter]="500">
                <button
                    *ngIf="!float"
                    kendoButton
                    #iconButton
                    class="Txt8 common-icon-button common-icon-button-{{type}}
                        common-icon-button-{{plane}} common-icon-button-{{plane}}-{{size}} common-icon-button-{{type}}-{{disabled}}"
                    [icon]="icon"
                    [title]="title ?? 'Icon Button'"
                    [disabled]="disabled"
                    type="button"
                    (click)="click($event)">
                    {{titleHidden ? '' : title}}
                </button>
                <kendo-floatingactionbutton
                    *ngIf="float"
                    #iconFloatButton
                    [offset]="offset"
                    [align]="align"
                    [positionMode]="positionMode"
                    [icon]="icon"
                    class="Txt8 common-icon-button common-icon-button-{{type}}
                        common-icon-button-{{plane}} common-icon-button-{{plane}}-{{size}} common-icon-button-{{type}}-{{disabled}}"
                    [title]="title ?? 'Button'"
                    [disabled]="disabled"
                    [text]="title ?? 'Button'"
                    (click)="click($event)">
                    {{title}}
                </kendo-floatingactionbutton>
            </div>
            <div *ngIf="!tooltip">
                <button
                    *ngIf="!float"
                    kendoButton
                    #iconButton
                    class="Txt8 common-icon-button common-icon-button-{{type}}
                        common-icon-button-{{plane}} common-icon-button-{{plane}}-{{size}} common-icon-button-{{type}}-{{disabled}}"
                    [icon]="icon"
                    [title]="title ?? 'Icon Button'"
                    [disabled]="disabled"
                    type="button"
                    (click)="click($event)">
                    {{titleHidden ? '' : title}}
                </button>
                <kendo-floatingactionbutton
                    *ngIf="float"
                    #iconFloatButton
                    [offset]="offset"
                    [align]="align"
                    [positionMode]="positionMode"
                    [icon]="icon"
                    class="Txt8 common-icon-button common-icon-button-{{type}}
                        common-icon-button-{{plane}} common-icon-button-{{plane}}-{{size}} common-icon-button-{{type}}-{{disabled}}"
                    [title]="title ?? 'Button'"
                    [disabled]="disabled"
                    [text]="title ?? 'Button'"
                    (click)="click($event)">
                    {{title}}
                </kendo-floatingactionbutton>
            </div>
        </ng-template>

        <ng-template #textTemplate>
            <div *ngIf="tooltip" kendoTooltip [showAfter]="500">
                <button
                    kendoButton
                    #textButton
                    class="Txt5 common-text-button common-text-button-{{size}} common-text-button-{{disabled}}"
                    [icon]="icon"
                    [title]="title ?? 'Text Button'"
                    [disabled]="disabled"
                    type="button"
                    (click)="click($event)">
                    {{titleHidden ? '' : title}}
                </button>
            </div>
            <div *ngIf="!tooltip">
                <button
                    kendoButton
                    #textButton
                    class="Txt5 common-text-button common-text-button-{{size}} common-text-button-{{disabled}}"
                    [icon]="icon"
                    [title]="title ?? 'Text Button'"
                    [disabled]="disabled"
                    type="button"
                    (click)="click($event)">
                    {{titleHidden ? '' : title}}
                </button>
            </div>
        </ng-template>

    </ng-container>
</div>

