import { Component, OnInit, Input } from '@angular/core';
import { LoaderType, LoaderThemeColor, LoaderSize, } from '@progress/kendo-angular-indicators';


@Component({
// tslint:disable-next-line:component-selector
selector: 'CDMS-common-load-indicator-control',
templateUrl: './common-load-indicator-control.component.html',
styleUrls: ['../assets/scss/_common-components.scss'],
})

export class CommonLoadIndicatorControl implements OnInit {

    @Input() show = false;
    @Input() type: LoaderType = 'converging-spinner';
    @Input() size: LoaderSize = 'medium';

    constructor() {
    }

    ngOnInit() {
    }

}
