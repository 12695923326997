import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { isEqual } from 'lodash';

import { AttendantSearch } from '../../models/attendant/AttendantSearch.model';
import { WithinRadiusAttendantResponse, ProviderDirectoryFunctionService}
  from 'src/app/services/providerdirectoryapi/providerdirectoryfunctionapi.services';
import { NotificationSystemService, INotification } from 'src/app/common-components/controllers/common-notification-system.controller';


@Injectable({
  providedIn: 'root',
})

export class AttendantSearchService implements OnDestroy {
  private _entity: BehaviorSubject<WithinRadiusAttendantResponse[]> = new BehaviorSubject<WithinRadiusAttendantResponse[]>(
    undefined
  );
  private _parameters: BehaviorSubject<AttendantSearch> = new BehaviorSubject<AttendantSearch>(
    undefined
  );
  private _load: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private pastParameters: AttendantSearch;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private searchService: ProviderDirectoryFunctionService,
    private notificationSystemService: NotificationSystemService,
  ) {}

  public search(data?: AttendantSearch): void {
    if (data !== undefined) {
      this._parameters.next(data);
    } else if (this._parameters.getValue()) {
      data = this._parameters.getValue();
    } else {
      return;
    }
    Object.keys(data).forEach(key => {
      if (data[key] === null) {
        delete data[key];
      }
    });
    if (!isEqual(this.pastParameters, data)) {
      this.pastParameters = new AttendantSearch(data);
      this._load.next(true);
      this.searchService.getAttendantsWithinRadius(
        data?.locationToCheck_Lat,
        data?.locationToCheck_Long,
        data?.radius,
        data?.zipCode,
        data?.hours,
        data?.day,
        data?.distanceType,
      )
      .pipe(
          take(1),
          takeUntil(this._destroying$)
      )
      .subscribe({
        next: (res) => {
          if (res) {
            this._entity.next(this.shuffle(res.data));
          }
          this._load.next(false);
        },
        error: error => {
          this._load.next(false);
          if (error.message) {
            const notification: INotification = {type: 'error', content: error.message};
            this.notificationSystemService.setNotification(notification);
          }
        }
      });
    } else {
      (async () => {
        this._load.next(true);
        await this.delay(1000);
        this._load.next(false);
      })();
    }
  }

  public fetchData(): Observable<WithinRadiusAttendantResponse[]> {
    this.search();
    return this._entity;
  }

  public getSearchParameters(): Observable<AttendantSearch> {
    return this._parameters;
  }

  public setSearchParameters(data: any) {
    this._parameters.next(data);
  }

  public getLoad(): Observable<boolean> {
    return this._load;
  }

  public shuffle(array: WithinRadiusAttendantResponse[]) {
    let currentIndex = array.length;
    let randomIndex: number;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
  }

  public delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  public clear(): void {
    this._entity.next(null);
    this._parameters.next(null);
  }

  public clearEntity(): void {
    this._entity.next(null);
  }

  public clearParamters(): void {
    this._parameters.next(null);
  }

  public clearLoad(): void {
    this._load.next(null);
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
