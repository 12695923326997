import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, } from '@angular/forms';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';

import { AppbarService } from '../../../components/appbar/appbar-service';
import { IKendoGridColumnSetting } from '../../../common-components/common-grid/IKendoGridColumnSetting';
import { KendoConstants } from '../../../common-components/constants/common-kendo-constants';

@Component({
  selector: 'app-admin-accounts',
  templateUrl: 'admin-accounts.page.html',
  styleUrls: ['admin-accounts.page.scss'],
})
export class AdminAccountsPage implements OnInit {


  public searchResults;
  public searchData = {
    firstName: null,
    lastName: null,
    id: null,
    email: null,
  };
  public searchForm;

  public columns: Array<IKendoGridColumnSetting>;
  public gridData: GridDataResult = {
    data: [],
    total: 0
  };
  public gridLoading = false;
  public pageSize: number = KendoConstants.kendoMasterGridProperties.pageSize;
  public pageNumber: number = KendoConstants.kendoMasterGridProperties.pageNumber;
  public state: State = {
    skip: 0,
    take: 10,
    filter: { logic: 'or', filters: [] },
    sort: [{ field: 'id', dir: 'asc' }],
  };


  constructor(
    private appbarService: AppbarService,
  ) {}

  ngOnInit(): void {
    this.setPage();
    this.setKendoGrid();
    this.setKendoGridDefaultValues();
    this.setForm();
  }

  public ionViewWillEnter(): void {
    this.setPage();
  }

  public setPage(): void {
    this.appbarService.setPage('admin-accounts');
  }

  private setKendoGridDefaultValues(): void {
    this.state.filter = { logic: 'and', filters: [] };
    this.pageSize = KendoConstants.kendoMasterGridProperties.pageSize;
    this.pageNumber = KendoConstants.kendoMasterGridProperties.pageNumber;
    this.state.skip = KendoConstants.kendoMasterGridProperties.skip;
    this.state.sort = [{ field: 'eventDate', dir: 'desc' }];
  }

  public setForm() {
    this.searchForm = new FormGroup({
      firstName: new FormControl(this.searchData.firstName, []),
      lastName: new FormControl(this.searchData.lastName, []),
      id: new FormControl(this.searchData.id, []),
      email: new FormControl(this.searchData.email, []),
    });
  }

  public search() {

  }

  public clear() {

  }

  public save(event) {

  }

  public update(event) {

  }

  public delete(event) {

  }


  public setKendoGrid() {
    this.columns = [
      {
        title: '',
        type: 'command',
      },
      {
        title: 'First Name',
        field: 'firstName',
        type: 'text',
        sortable: false,
        filterable: false,
        width: 150,
      },
      {
        title: 'Last Initial',
        field: 'lastName',
        type: 'text',
        sortable: false,
        filterable: false,
        width: 150,
      },
      {
        title: 'ID',
        field: 'id',
        type: 'text',
        sortable: false,
        filterable: false,
        width: 150,
      },
      {
        title: 'Email',
        field: 'email',
        type: 'text',
        sortable: false,
        filterable: false,
        width: 150,
      },
    ];
  }
}
