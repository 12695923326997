import { Component, OnInit } from '@angular/core';

import { AppbarService } from '../../components/appbar/appbar-service';

@Component({
  selector: 'app-requirements',
  templateUrl: 'requirements.page.html',
  styleUrls: ['requirements.page.scss'],
})
export class RequirementsPage implements OnInit {

  constructor(
    private appbarService: AppbarService,
    ) {
    }

    ngOnInit(): void {
      this.setPage();
    }

    ionViewWillEnter(): void {
      this.setPage();
    }

    public setPage() {
      this.appbarService.setPage('requirements');
    }
}
