<div class="page-wrapper">
    <div class="column text-column m-l-40 m-r-40 m-t-40">
        <div>
            <p class="page-subtitle-1">{{'page_Requirements_CDASS_title' | langchange}}</p>
        </div>
        <div>
            <p class="page-text">{{'page_Requirements_CDASS_p1' | langchange}}</p>
            <p class="page-text m-t-20">{{'page_Requirements_CDASS_p2' | langchange}}</p>
            <div class="row m-t-20">
                <p class="page-text">{{'page_Requirements_CDASS_p3' | langchange}}</p>
                &nbsp;
                <a
                    title="{{'page_Requirements_CDASS_p4' | langchange}}"
                    href="https://hcpf.colorado.gov/single-entry-point-agencies"
                    class="link">
                    {{'page_Requirements_CDASS_p4' | langchange}}
                </a>
            </div>
        </div>
        <div>
            <p class="page-text m-t-20">{{'page_Requirements_CDASS_p5' | langchange}}:</p>
        </div>
        <div class="row">
            <div class="">
                <a href="https://consumerdirectco.com/"
                    title="{{'title_Home' | langchange}}"
                    class="CDCO-logo-image"
                    type="button">
                </a>
            </div>
            <div class="m-l-40">
                <a href="https://hcpf.colorado.gov/consumer-directed-attendant-support-services"
                    title="{{'title_Home' | langchange}}"
                    class="HCPF-logo-image"
                    type="button">
                </a>
            </div>
        </div>

        <div class="m-t-40">
            <p class="page-subtitle-1">{{'page_Requirements_Employed_title' | langchange}}</p>
        </div>
        <div>
            <p class="page-text">{{'page_Requirements_Employed_p1' | langchange}}:</p>
            <ul>
                <li class="page-text">{{'page_Requirements_Employed_p2' | langchange}}</li>
                <li class="page-text">{{'page_Requirements_Employed_p3' | langchange}}</li>
                <li class="page-text">{{'page_Requirements_Employed_p4' | langchange}}</li>
            </ul>
            <p class="page-text">{{'page_Requirements_Employed_p5' | langchange}}</p>

        </div>

        <div class="m-t-20">
            <p class="page-subtitle-2">{{'page_Requirements_Employed_p6' | langchange}}:</p>
            <p class="page-text">{{'page_Requirements_Employed_p7' | langchange}}</p>
        </div>
        <div>
            <ul>
                <li class="page-text">{{'page_Requirements_Employed_p8' | langchange}}</li>
                <li class="page-text">{{'page_Requirements_Employed_p9' | langchange}}</li>
                <li class="page-text">{{'page_Requirements_Employed_p10' | langchange}}</li>
                <li class="page-text">{{'page_Requirements_Employed_p11' | langchange}}</li>
                <li class="page-text">{{'page_Requirements_Employed_p12' | langchange}}</li>
                <li class="page-text">{{'page_Requirements_Employed_p13' | langchange}}</li>
                <li class="page-text">{{'page_Requirements_Employed_p14' | langchange}}</li>
                <li class="page-text">{{'page_Requirements_Employed_p15' | langchange}}</li>
                <li class="page-text">{{'page_Requirements_Employed_p16' | langchange}}</li>
                <li class="page-text">{{'page_Requirements_Employed_p17' | langchange}}</li>
                <li class="page-text">{{'page_Requirements_Employed_p18' | langchange}}</li>
                <li class="page-text">{{'page_Requirements_Employed_p19' | langchange}}</li>
                <li class="page-text">{{'page_Requirements_Employed_p20' | langchange}}</li>
                <li class="page-text">{{'page_Requirements_Employed_p21' | langchange}}</li>
                <li class="page-text">{{'page_Requirements_Employed_p22' | langchange}}</li>
                <li class="page-text">{{'page_Requirements_Employed_p23' | langchange}}</li>
            </ul>
        </div>


        <div class="m-t-20">
            <p class="page-subtitle-2">{{'page_Requirements_Employed_p24' | langchange}}:</p>
        </div>
        <div>
            <ul>
                <li class="page-text">{{'page_Requirements_Employed_p25' | langchange}}</li>
                <li class="page-text">{{'page_Requirements_Employed_p26' | langchange}}</li>
            </ul>
        </div>


        <div class="m-t-40">
            <p class="page-subtitle-2">{{'page_Requirements_Employed_p27' | langchange}}:</p>
        </div>
        <div class="m-t-20">
            <p class="page-text">{{'page_Requirements_Employed_p28' | langchange}}</p>
            <p class="page-text">{{'page_Requirements_Employed_p29' | langchange}}</p>
            <p class="page-text">{{'page_Requirements_Employed_p30' | langchange}}</p>
            <p class="page-text">{{'page_Requirements_Employed_p31' | langchange}}</p>
        </div>

        <div class="row m-t-20">
            <p class="page-text">{{'title_Phone' | langchange}}:</p>
            &nbsp;
            <p class="page-text">844-381-4433</p>
        </div>
        <div class="row">
            <p class="page-text">{{'title_Fax' | langchange}}:</p>
            &nbsp;
            <p class="page-text">866-924-9072</p>
        </div>
        <div class="row">
            <p class="page-text">{{'title_Email' | langchange}}:</p>
            &nbsp;
            <p class="page-text">InfoVeterans@consumerdirectcare.com</p>
        </div>
        
    </div>
</div>