<div *ngIf="attendantDataForm" class="page-wrapper">

    <form
        [formGroup]="attendantDataForm">

        <!-- FLOATING BUTTONS -->
        <div>
            <div *ngIf="attendantData.status" class="profile-status page-subtitle-2 z-3">
                <CDMS-status-indicator-control
                    label="{{'title_Profile' | langchange}}"
                    [status]="attendantData?.status">
                </CDMS-status-indicator-control>
            </div>
            <div>
                <CDMS-common-button-control
                    title="{{'title_Cancel' | langchange}}"
                    [button]="'standard'"
                    [size]="'small'"
                    [type]="'secondary'"
                    [float]="true"
                    [positionMode]="'absolute'"
                    [align]="{vertical: 'top', horizontal: 'end'}"
                    [offset]="{x: floatOffset, y: '100px'}"
                    (clickEvent)="cancel()">
                </CDMS-common-button-control>
            </div>
            <div>
                <CDMS-common-button-control
                    title="{{'title_Save' | langchange}}"
                    [button]="'standard'"
                    [size]="'small'"
                    [type]="'primary'"
                    [float]="true"
                    [positionMode]="'absolute'"
                    [align]="{vertical: 'top', horizontal: 'end'}"
                    [offset]="{x: '25px', y: '100px'}"
                    [disabled]="attendantDataForm.invalid"
                    (clickEvent)="save()">
                </CDMS-common-button-control>
            </div>
        </div>

        <div class="row">
            <!-- PROFILE PICTURE COLUMN -->
            <div class="picture-column column">
                <div class="page-subsection column">
                    <img
                        class="profile-picture"
                        title="{{'title_ProfilePicture' | langchange}}"
                        [src]="attendantData?.photoUrl === '' ? '/assets/images/default-avatar.png' : attendantData?.photoUrl"
                        [alt]="'profile-picture'">
                    <div *ngIf="!fileLoad">
                        <CDMS-common-upload-control
                            [type]="'picture'"
                            (upload)="profilePictureUpload($event)">
                        </CDMS-common-upload-control>
                    </div>
                    <CDMS-common-load-indicator-control
                        [show]="fileLoad">
                    </CDMS-common-load-indicator-control>
                    <div *ngIf="statusPic">
                        <CDMS-status-indicator-control
                            label="{{'title_ProfilePicture' | langchange}}"
                            [status]="statusPic.status">
                        </CDMS-status-indicator-control>
                    </div>
                </div>
            </div>

            <!-- PROFILE DETAILS COLUMN -->
            <div class="details-column column">
                <div class="page-subsection column">
                    <p class="page-subtitle-1">{{'page_Attendent_BasicInfo' | langchange}}</p>
                    <div class="row m-t-20">
                        <div>
                            <CDMS-common-input-control
                                title="{{'title_FirstName' | langchange}}"
                                [control]="$any(attendantDataForm.get('firstName'))"
                                ngDefaultControl
                                formControlName="firstName"
                                [input]="'text'"
                                [size]="'medium'"
                                [value]="attendantData.firstName">
                            </CDMS-common-input-control>
                        </div>
                    </div>
                    <div class="row">
                        <div>
                            <CDMS-common-input-control
                                title="{{'title_LastInitial' | langchange}}"
                                [control]="$any(attendantDataForm.get('lastName'))"
                                ngDefaultControl
                                formControlName="lastName"
                                [input]="'text'"
                                [size]="'xsmall'"
                                [maxLength]="1"
                                [value]="attendantData.lastName">
                            </CDMS-common-input-control>
                        </div>
                    </div>
                    <div class="row">
                        <div>
                            <div>
                                <CDMS-common-input-control
                                    title="{{'title_Email' | langchange}}"
                                    [control]="$any(attendantDataForm.get('email'))"
                                    [disableInput]="attendantData.email !== null && attendantData.email !== undefined"
                                    ngDefaultControl
                                    formControlName="email"
                                    [input]="'text'"
                                    [size]="'medium'"
                                    [value]="attendantData.email">
                                </CDMS-common-input-control>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div>
                            <div>
                                <CDMS-common-input-control
                                    title="{{'title_DateOfBirth' | langchange}}"
                                    [control]="$any(attendantDataForm.get('dob'))"
                                    ngDefaultControl
                                    formControlName="dob"
                                    [input]="'date'"
                                    [size]="'small'"
                                    [value]="attendantData.dob">
                                </CDMS-common-input-control>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="m-r-20">
                            <div>
                                <CDMS-common-input-control
                                    title="{{'title_Zipcode' | langchange}}"
                                    [control]="$any(attendantDataForm.get('zip'))"
                                    ngDefaultControl
                                    formControlName="zip"
                                    [input]="'text'"
                                    [size]="'small'"
                                    [value]="attendantData.zip">
                                </CDMS-common-input-control>
                            </div>
                        </div>
                        <div class="m-r-40">
                            <div>
                                <CDMS-common-input-control
                                    title="{{'title_State' | langchange}}"
                                    [control]="$any(attendantDataForm.get('state'))"
                                    ngDefaultControl
                                    formControlName="state"
                                    [input]="'dropdown'"
                                    [options]="stateOptions"
                                    [size]="'small'"
                                    [value]="attendantData.state">
                                </CDMS-common-input-control>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="m-r-40">
                            <div>
                                <CDMS-common-input-control
                                    title="{{'title_DistanceTravel' | langchange}}"
                                    [control]="$any(attendantDataForm.get('travelDistance'))"
                                    ngDefaultControl
                                    formControlName="travelDistance"
                                    [input]="'dropdown'"
                                    [options]="travelOptions"
                                    [size]="'small'"
                                    [value]="attendantData.travelDistance">
                                </CDMS-common-input-control>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div>
                            <CDMS-common-input-control
                                title="{{'title_PersonalSummary' | langchange}}"
                                [infoSlideout]="true"
                                infoSlideoutText="{{'page_Attendent_PersonalSummaryInfo' | langchange}}"
                                [showCounter]="true"
                                [maxLength]="100"
                                [control]="$any(attendantDataForm.get('snippet'))"
                                ngDefaultControl
                                formControlName="snippet"
                                [input]="'textarea'"
                                [size]="'medium'"
                                [value]="attendantData.snippet">
                            </CDMS-common-input-control>
                        </div>
                    </div>
                    <div *ngIf="statusSnippet">
                        <CDMS-status-indicator-control
                            label="{{'title_PersonalSummary' | langchange}}"
                            [status]="statusSnippet.status">
                        </CDMS-status-indicator-control>
                    </div>
                    <div class="row">
                        <div>
                            <CDMS-common-input-control
                                title="{{'title_PreferredLanguages' | langchange}}"
                                [control]="$any(attendantDataForm.get('languages'))"
                                ngDefaultControl
                                formControlName="languages"
                                [input]="'checkboxlist'"
                                [size]="'medium'"
                                [options]="languageOptions | langTransform"
                                textField="name"
                                valueField="id"
                                [value]="attendantData.languages">
                            </CDMS-common-input-control>
                        </div>
                    </div>
                </div>
                <div class="page-subsection column">
                    <div class="row">
                        <p class="page-subtitle-1">{{'page_Attendent_MoreAbout' | langchange}}</p>
                        <div>
                            <CDMS-common-info-slideout-control
                                text="{{'page_Attendent_MoreAboutInfo' | langchange}}"
                                [position]="'right'"
                                [iconSize]="'medium'"
                                [slideoutSize]="'large'">
                            </CDMS-common-info-slideout-control>
                        </div>
                    </div>
                    <div class="row">
                        <div>
                            <CDMS-common-input-control
                                [showCounter]="true"
                                [maxLength]="500"
                                [control]="$any(attendantDataForm.get('about'))"
                                ngDefaultControl
                                formControlName="about"
                                [input]="'textarea'"
                                [size]="'large'"
                                [value]="attendantData.about">
                            </CDMS-common-input-control>
                        </div>
                    </div>
                    <div *ngIf="statusAboutMe">
                        <CDMS-status-indicator-control
                            label="{{'page_Attendent_MoreAbout' | langchange}}"
                            [status]="statusAboutMe.status">
                        </CDMS-status-indicator-control>
                    </div>
                </div>
                <div class="page-subsection column">
                    <p class="page-subtitle-1">{{'title_Employment' | langchange}}</p>
                    <div class="row">
                        <div>
                            <CDMS-common-input-control
                                title="{{'page_Attendent_YearsExperience' | langchange}}"
                                [control]="$any(attendantDataForm.get('experience'))"
                                ngDefaultControl
                                formControlName="experience"
                                [format]="'##'"
                                [input]="'numeric'"
                                [size]="'small'"
                                [value]="attendantData.experience">
                            </CDMS-common-input-control>
                        </div>
                    </div>
                    <div class="row">
                        <div class="m-r-20">
                            <div>
                                <CDMS-common-input-control
                                    title="{{'page_Attendent_PreferredStartingDate' | langchange}}"
                                    [control]="$any(attendantDataForm.get('prefHireDate'))"
                                    ngDefaultControl
                                    formControlName="prefHireDate"
                                    [input]="'date'"
                                    [size]="'small'"
                                    [value]="attendantData.prefHireDate">
                                </CDMS-common-input-control>
                            </div>
                        </div>
                        <div>
                            <div>
                                <CDMS-common-input-control
                                    title="{{'page_Attendent_DateQuickSelect' | langchange}}"
                                    [control]="$any(attendantDataForm.get('dateQuickSelect'))"
                                    ngDefaultControl
                                    formControlName="dateQuickSelect"
                                    [input]="'dropdown'"
                                    [size]="'small'"
                                    [options]="dateOptions | langTransform"
                                    (valueChange)="dateQuickSelect($event)">
                                </CDMS-common-input-control>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p class="page-subtitle-2">{{'page_Attendent_WeeklyWorkSchedule' | langchange}}</p>
                        <p class="page-text">{{'page_Attendent_WeeklyWorkPreference' | langchange}}</p>
                        <div class="m-r-20">
                            <CDMS-weekly-work-schedule-control
                                [schedule]="attendantData.schedules"
                                (valueChange)="setSchedule($event)">
                            </CDMS-weekly-work-schedule-control>
                        </div>
                    </div>
                    <div class="row m-t-20">
                        <div>
                            <CDMS-common-input-control
                                title="{{'title_TotalWeeklyHours' | langchange}}"
                                [control]="$any(attendantDataForm.get('weeklyHours'))"
                                ngDefaultControl
                                formControlName="weeklyHours"
                                [format]="'##'"
                                [input]="'numeric'"
                                [size]="'small'"
                                [value]="attendantData.weeklyHours">
                            </CDMS-common-input-control>
                        </div>
                    </div>
                    <div>
                        <p class="page-subtitle-2">{{'page_Attendent_WorkEnvironmentPref' | langchange}}</p>
                        <p class="page-text m-r-20">{{'page_Attendent_WorkEnvironmentPrefInfo' | langchange}}</p>
                        <div class="m-t-20 m-r-20">
                            <div>
                                <CDMS-common-input-control
                                    [control]="$any(attendantDataForm.get('workPreferences'))"
                                    ngDefaultControl
                                    formControlName="workPreferences"
                                    [input]="'checkboxlist'"
                                    [size]="'large'"
                                    [options]="workPrefOptions | langTransform"
                                    textField="name"
                                    valueField="id"
                                    [(value)]="attendantData.workPreferences">
                                </CDMS-common-input-control>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page-subsection column">
                    <p class="page-subtitle-1">{{'page_Attendent_ServicesProvided' | langchange}}</p>
                    <p class="page-text m-r-20">{{'title_PleaseSelectAll' | langchange}}</p>
                    <div class="m-r-20">
                        <div>
                            <CDMS-common-input-control
                                [control]="$any(attendantDataForm.get('services'))"
                                ngDefaultControl
                                formControlName="services"
                                [input]="'checkboxlist'"
                                [size]="'large'"
                                [options]="serviceOptions | langTransform"
                                textField="name"
                                valueField="id"
                                [(value)]="attendantData.services">
                            </CDMS-common-input-control>
                        </div>
                    </div>
                </div>
                <div class="page-subsection column">
                    <p class="page-subtitle-1">{{'title_Certifications' | langchange}}</p>
                    <p class="page-text m-r-20">{{'title_PleaseSelectAll' | langchange}}</p>
                    <div class="m-r-20">
                        <div>
                            <CDMS-common-input-control
                                [control]="$any(attendantDataForm.get('certifications'))"
                                ngDefaultControl
                                formControlName="certifications"
                                [input]="'checkboxlist'"
                                [size]="'large'"
                                [options]="certOptions | langTransform"
                                textField="name"
                                valueField="id"
                                [(value)]="attendantData.certifications">
                            </CDMS-common-input-control>
                        </div>
                    </div>
                </div>
                <div class="page-subsection column">
                    <div class="row m-b-10">
                        <p class="page-subtitle-1">{{'title_ProfileStatus' | langchange}}</p>
                        <CDMS-common-info-slideout-control
                            text="{{'page_Attendent_ProfileStatusInfo' | langchange}}"
                            [position]="'right'"
                            [iconSize]="'medium'"
                            [slideoutSize]="'large'">
                        </CDMS-common-info-slideout-control>
                    </div>
                    <div>
                        <div class="row">
                            <CDMS-common-input-control
                                [control]="$any(attendantDataForm.get('isActive'))"
                                ngDefaultControl
                                formControlName="isActive"
                                [input]="'checkbox'"
                                [size]="'xsmall'"
                                [value]="attendantData.isActive"
                                class="m-r-10">
                            </CDMS-common-input-control>
                            <p class="page-text">{{'title_Active' | langchange}}</p>
                        </div>
                    </div>
                </div>
                <div class="page-subsection column">
                    <p class="page-subtitle-1 m-b-10">{{'title_Security' | langchange}}</p>
                    <div class="row m-b-10">
                        <CDMS-common-button-control
                            title="{{'title_ChangeEmail' | langchange}}"
                            [button]="'text'"
                            [size]="'medium'"
                            (clickEvent)="goFlow('ChangeSignIn')">
                        </CDMS-common-button-control>
                    </div>
                    <div class="row">
                        <CDMS-common-button-control
                            title="{{'title_ChangePassword' | langchange}}"
                            [button]="'text'"
                            [size]="'medium'"
                            (clickEvent)="goFlow('PasswordReset')">
                        </CDMS-common-button-control>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- MODALS -->
<CDMS-common-confirmation-modal-control
    [modalType]="'confirm'"
    [showDialog]="showChangesDialog"
    [title]="'title_SaveChanges' | langchange"
    [bodyText]="'page_Attendent_ChangeModal' | langchange"
    [affirmText]="'title_Confirm' | langchange"
    [denyText]="'title_Cancel' | langchange"
    (affirmEvent)="closeChangesModal('yes')"
    (denyEvent)="closeChangesModal('no')"
    (closeEvent)="closeChangesModal('no')">
</CDMS-common-confirmation-modal-control>

<CDMS-common-confirmation-modal-control
    [modalType]="'confirm'"
    [showDialog]="showPasswordDialog"
    [title]="'title_ChangePassword' | langchange"
    [bodyText]=""
    [affirmText]="'title_Confirm' | langchange"
    [denyText]="'title_Cancel' | langchange"
    (affirmEvent)="closePasswordModal('yes')"
    (denyEvent)="closePasswordModal('no')"
    (closeEvent)="closePasswordModal('no')">
</CDMS-common-confirmation-modal-control>

<CDMS-common-confirmation-modal-control
    [modalType]="'confirm'"
    [showDialog]="showDeleteDialog"
    [title]="'title_WARNING' | langchange"
    [bodyText]="'page_Attendent_DeleteModal' | langchange"
    [affirmText]="'title_Confirm' | langchange"
    [denyText]="'title_Cancel' | langchange"
    (affirmEvent)="closeDeleteModal('yes')"
    (denyEvent)="closeDeleteModal('no')"
    (closeEvent)="closeDeleteModal('no')">
</CDMS-common-confirmation-modal-control>
