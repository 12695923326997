export class CommonConstants {

  // DateTime
  public static readonly minSmallDate: Date = new Date(1900, 0, 1); // 01/01/1990
  public static readonly maxSmallDate: Date = new Date(2079, 5, 6); // 06/06/2079
  public static readonly minSmallDateTime: Date = new Date(1900, 0, 1, 0, 0, 0);
  public static readonly maxSmallDateTime: Date = new Date(2079, 5, 6, 23, 59, 59);
  public static readonly maxDate: Date = new Date(9999, 11, 31);
  public static readonly defaultDateFormat = 'MM/dd/yyyy';

  // Single Word
  public static readonly yes = 'Yes';
  public static readonly no = 'No';
  public static readonly ok = 'OK';
  public static readonly continue = 'Continue';
  public static readonly save = 'Save';
  public static readonly confirm = 'Confirm';
  public static readonly close = 'Close';
  public static readonly cancel = 'Cancel';
  public static readonly delete = 'Delete';
  public static readonly update = 'Update';
  public static readonly copy = 'Copy';

  // Confirmation Messages
  public static readonly continueMessage = 'Are you sure you want to continue?';
  public static readonly saveMessage = 'Would you like to save and continue?';
  public static readonly confirmMessage = 'Are you sure?';
  public static readonly cancelMessage = 'Are you sure you want to cancel?';
  public static readonly deleteMessage = 'Are you sure you want to delete the selected record?';
  public static readonly unsavedChangesMessage = 'This action will lose your unsaved changes, do you want to continue?';

  // Error Messages
  public static errorMessages =
  {
    dateRangeOverlap: 'Date range overlaps another range',
    endDateLessThanOrEqualStartDate: 'Start date after end date',
    endDateLessThanStartDate: 'Start date after or on end date',
    incompleteSSN: 'SSN should be 9 digits',
    invalidSSN: 'Invalid SSN',
    invalidDate: 'Invalid date',
    invalidSmallDateMin: 'Date must be after 01/01/1900',
    invalidDateMax: 'Date must be before 12/31/9999',
    invalidEmail: 'Invalid email',
    invalidPhone: 'Phone number should be 10 digits',
    invalidZip: 'Invalid zip code',
    negativeOrZero: 'Enter a positive number',
    negative: 'Enter 0 or greater',
    required: 'Required',
    requiredEmail: 'Required to send email',
    maxLength: 'Max length exceeded',
    workWeekLimitStartDayInvalid: 'Invalid Start Date',
    workWeekLimitEndDayInvalid: 'Invalid End Date'
  };

  // Template Names
  public static templates =
  {
    providerEmail: 'ProviderEmailTemplate.html',
    approvedTemplate: 'ApprovedTemplate.html',
    rejectedTemplate: 'RejectedTemplate.html',
    registrationTemplate: 'RegistrationTemplate.html',
    passwordResetTemplate: 'PasswordResetTemplate.html',
  };
}
