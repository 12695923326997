import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Roles, Pages } from 'src/app/constants/app-types';


@Injectable()
export class AppbarService{

  public currentPage: Pages = 'home';

  constructor(){}

  private _adminTrigger = new BehaviorSubject<string>('');
  private _roleTrigger = new BehaviorSubject<Roles>('User');
  private _pageTrigger = new BehaviorSubject<Pages>('home');


  getPageTrigger(){
    return this._pageTrigger.asObservable();
  }

  getRoleTrigger(){
    return this._roleTrigger.asObservable();
  }

  getAdminTrigger(){
    return this._adminTrigger.asObservable();
  }

  complete() {
    this._adminTrigger.next('complete');
    this.clear();
  }
  cancel() {
    this._adminTrigger.next('cancel');
    this.clear();
  }
  delete() {
    this._adminTrigger.next('delete');
    this.clear();
  }
  spam() {
    this._adminTrigger.next('spam');
    this.clear();
  }
  refresh() {
    this._adminTrigger.next('refresh');
    this.clear();
  }
  clear() {
    this._adminTrigger.next('');
  }

  setPage(str?: Pages) {
    this._adminTrigger.next(null);
    if (str === undefined) {
      str = this.currentPage;
    }
    this._pageTrigger.next(str);
    this.currentPage = str;
  }

  setRole(str: Roles) {
    this._roleTrigger.next(str);
  }
}
