
<!-- APPBAR -->
<div *ngIf="show" class="appbar-wrapper">
    <kendo-appbar position="top">

        <!-- APPBAR ROLES SWITCH -->
        <ng-container [ngSwitch]="role">

            <div *ngSwitchCase="'User'">
                <ng-container *ngTemplateOutlet="userTemplate"></ng-container>
            </div>
            <div *ngSwitchCase="'ProviderDirectoryAdmin'">
                <ng-container *ngTemplateOutlet="adminTemplate"></ng-container>
            </div>
            <div *ngSwitchCase="'Attendant'">
                <ng-container *ngTemplateOutlet="attendantTemplate"></ng-container>
            </div>
            <div *ngSwitchDefault>
                <ng-container *ngTemplateOutlet="userTemplate"></ng-container>
            </div>

        </ng-container>
    </kendo-appbar>
</div>

<!-- APPBAR TEMPLATES -->
<!-- USER -->
<ng-template #userTemplate>
    <div class="appbar-body">

        <kendo-appbar-spacer class="appbar-spacer-end"></kendo-appbar-spacer>

        <kendo-appbar-section class="appbar-section appbar-button">
            <CDMS-common-button-control
                title="{{'title_SignIn' | langchange}}"
                class="flex-center"
                [button]="'icon'"
                [icon]="'login'"
                [size]="'medium'"
                type="primary"
                [plane]="'vertical'"
                (clickEvent)="goLogin()">
            </CDMS-common-button-control>
        </kendo-appbar-section>

        <kendo-appbar-section class="appbar-section appbar-button">
            <CDMS-common-button-control
                title="{{'title_Language' | langchange}}"
                class="flex-center"
                [button]="'icon'"
                [icon]="'globe'"
                [size]="'medium'"
                type="primary"
                [plane]="'vertical'"
                (clickEvent)="changeLanguage()">
            </CDMS-common-button-control>
        </kendo-appbar-section>

        <kendo-appbar-section #helpButton class="appbar-button appbar-section">
            <CDMS-common-button-control
                title="{{'title_PageHelp' | langchange}}"
                class="flex-center"
                [button]="'icon'"
                [icon]="'question'"
                [size]="'medium'"
                type="primary"
                [plane]="'vertical'"
                (clickEvent)="toggleHelp()">
            </CDMS-common-button-control>
        </kendo-appbar-section>
    
        <kendo-appbar-spacer class="appbar-spacer"></kendo-appbar-spacer>

        <kendo-appbar-section class="appbar-section appbar-section-title">
            <h1 class="title">{{activePageDetail?.pageTitle}}</h1>
        </kendo-appbar-section>

        <kendo-appbar-spacer class="appbar-spacer-fill"></kendo-appbar-spacer>
        
        <kendo-appbar-section
            *ngIf="colorado"
            class="appbar-section-double m-r-20">
            <div
                class="logo-image">
            </div>
        </kendo-appbar-section>

        <kendo-appbar-spacer
            class="appbar-spacer-end">
        </kendo-appbar-spacer>

        <kendo-appbar-section class="appbar-section-double">
            <button
                title="{{'title_Home' | langchange}}"
                class="{{colorado ? 'logo-button-co' : 'logo-button'}}"
                (click)="goHome()"
                type="button">
            </button>
        </kendo-appbar-section>

        <kendo-appbar-spacer class="appbar-spacer-end"></kendo-appbar-spacer>
    </div>
</ng-template>


<!-- ATTENDANT -->
<ng-template #attendantTemplate>
    <div class="appbar-body">

        <kendo-appbar-spacer class="appbar-spacer-end"></kendo-appbar-spacer>

        <kendo-appbar-section #menuButton class="appbar-section appbar-button">
            <CDMS-common-button-control
                title="{{'title_Menu' | langchange}}"
                [titleHidden]="true"
                class="flex-center"
                [button]="'icon'"
                [icon]="'menu'"
                [size]="'small'"
                type="primary"
                [plane]="'vertical'"
                (clickEvent)="toggleMenu()">
            </CDMS-common-button-control>
        </kendo-appbar-section>

        <kendo-appbar-spacer class="appbar-spacer"></kendo-appbar-spacer>

        <kendo-appbar-section #helpButton class="appbar-section appbar-button">
            <CDMS-common-button-control
                title="{{'title_PageHelp' | langchange}}"
                class="flex-center"
                [button]="'icon'"
                [icon]="'question'"
                [size]="'medium'"
                type="primary"
                [plane]="'vertical'"
                (clickEvent)="toggleHelp()">
            </CDMS-common-button-control>
        </kendo-appbar-section>
    
        <kendo-appbar-spacer class="appbar-spacer"></kendo-appbar-spacer>

        <kendo-appbar-section class="appbar-section appbar-section-title">
            <h1 class="title">{{activePageDetail?.pageTitle}}</h1>
        </kendo-appbar-section>

        <kendo-appbar-spacer class="appbar-spacer-fill"></kendo-appbar-spacer>

        <kendo-appbar-section
            *ngIf="colorado"
            class="appbar-section-double m-r-20">
            <div
                class="logo-image">
            </div>
        </kendo-appbar-section>

        <kendo-appbar-spacer
            class="appbar-spacer-end">
        </kendo-appbar-spacer>

        <kendo-appbar-section class="appbar-section-double">
            <button
                title="{{'title_Home' | langchange}}"
                class="{{colorado ? 'logo-button-co' : 'logo-button'}}"
                (click)="goHome()"
                type="button">
            </button>
        </kendo-appbar-section>

        <kendo-appbar-spacer class="appbar-spacer-end"></kendo-appbar-spacer>
    </div>
</ng-template>


<!-- ADMIN -->
<ng-template #adminTemplate>
    <div class="appbar-body">

        <kendo-appbar-spacer class="appbar-spacer-end"></kendo-appbar-spacer>

        <kendo-appbar-section #menuButton class="appbar-section appbar-button">
            <CDMS-common-button-control
                title="{{'title_Menu' | langchange}}"
                [titleHidden]="true"
                class="flex-center"
                [button]="'icon'"
                [icon]="'menu'"
                [size]="'small'"
                type="primary"
                [plane]="'vertical'"
                (clickEvent)="toggleMenu()">
            </CDMS-common-button-control>
        </kendo-appbar-section>

        <kendo-appbar-spacer class="appbar-spacer"></kendo-appbar-spacer>

        <!-- Admin App Bar when on Admin pages -->
        <ng-container *ngIf="activePageDetail?.page !== 'attendant'">
            <kendo-appbar-section class="appbar-section appbar-button">
                <CDMS-common-button-control
                    *ngIf="colorado"
                    title="{{'title_Attendants' | langchange}}"
                    class="flex-center"
                    [button]="'icon'"
                    [icon]="'user'"
                    [size]="'medium'"
                    type="primary"
                    [plane]="'vertical'"
                    (clickEvent)="goAttendants()">
                </CDMS-common-button-control>
                <CDMS-common-button-control
                    *ngIf="!colorado"
                    title="{{'title_Providers' | langchange}}"
                    class="flex-center"
                    [button]="'icon'"
                    [icon]="'user'"
                    [size]="'medium'"
                    type="primary"
                    [plane]="'vertical'"
                    (clickEvent)="goAttendants()">
                </CDMS-common-button-control>
            </kendo-appbar-section>

            <kendo-appbar-section class="appbar-section appbar-button">
                <CDMS-common-button-control
                    [hidden]="true"
                    title="{{'title_Accounts' | langchange}}"
                    class="flex-center"
                    [button]="'icon'"
                    [icon]="'gears'"
                    [size]="'medium'"
                    type="primary"
                    [plane]="'vertical'"
                    (clickEvent)="goAccounts()">
                </CDMS-common-button-control>
            </kendo-appbar-section>    
        </ng-container>

        <!-- Admin App Bar when on Attendant Pages -->
        <ng-container *ngIf="activePageDetail?.page === 'attendant'">
            <kendo-appbar-section class="appbar-section appbar-button">
                <CDMS-common-button-control
                    title="{{'title_Complete' | langchange}}"
                    class="flex-center"
                    [button]="'icon'"
                    [icon]="'check-circle'"
                    [size]="'medium'"
                    type="primary"
                    [plane]="'vertical'"
                    (clickEvent)="complete()">
                </CDMS-common-button-control>
            </kendo-appbar-section>

            <kendo-appbar-section class="appbar-section appbar-button">
                <CDMS-common-button-control
                    title="{{'title_Cancel' | langchange}}"
                    class="flex-center"
                    [button]="'icon'"
                    [icon]="'cancel'"
                    [size]="'medium'"
                    type="primary"
                    [plane]="'vertical'"
                    (clickEvent)="cancel()">
                </CDMS-common-button-control>
            </kendo-appbar-section>

            <kendo-appbar-section class="appbar-section appbar-button">
                <CDMS-common-button-control
                    title="{{'title_Delete' | langchange}}"
                    class="flex-center"
                    [button]="'icon'"
                    [icon]="'delete'"
                    [size]="'medium'"
                    type="primary"
                    [plane]="'vertical'"
                    (clickEvent)="delete()">
                </CDMS-common-button-control>
            </kendo-appbar-section>

            <kendo-appbar-section class="appbar-section appbar-button">
                <CDMS-common-button-control
                    title="{{'title_Spam' | langchange}}"
                    class="flex-center"
                    [button]="'icon'"
                    [icon]="'warning'"
                    [size]="'medium'"
                    type="primary"
                    [plane]="'vertical'"
                    (clickEvent)="spam()">
                </CDMS-common-button-control>
            </kendo-appbar-section>
        </ng-container>

        <kendo-appbar-spacer class="appbar-spacer-fill"></kendo-appbar-spacer>

        <kendo-appbar-section #back class="appbar-section appbar-button">
            <CDMS-common-button-control
                title="{{'title_Back' | langchange}}"
                class="flex-center"
                [button]="'icon'"
                [icon]="'undo'"
                [size]="'medium'"
                type="primary"
                [plane]="'vertical'"
                (clickEvent)="goBack()">
            </CDMS-common-button-control>
        </kendo-appbar-section>

        <kendo-appbar-spacer class="appbar-spacer-end"></kendo-appbar-spacer>
    </div>
</ng-template>


<!-- MENU DROPDOWN -->
<div *ngIf="showMenu" class="dropdown-body-wrapper">
    <kendo-popup
        #menuPopup
        [anchor]="'menuButton'"
        [anchorAlign]="anchorAlignMenu"
        [popupAlign]="popupAlignMenu"
        [animate]="getAnimation"
        (anchorViewportLeave)="toggleMenu()">
        <div class="dropdown-body dropdown-menu">

            <!-- MENU ROLES SWITCH -->
            <ng-container [ngSwitch]="role">

                <div *ngSwitchCase="'User'">
                    <ng-container *ngTemplateOutlet="userMenuTemplate"></ng-container>
                </div>
                <div *ngSwitchCase="'ProviderDirectoryAdmin'">
                    <ng-container *ngTemplateOutlet="adminMenuTemplate"></ng-container>
                </div>
                <div *ngSwitchCase="'Attendant'">
                    <ng-container *ngTemplateOutlet="attendantMenuTemplate"></ng-container>
                </div>
                <div *ngSwitchDefault>
                    <ng-container *ngTemplateOutlet="userMenuTemplate"></ng-container>
                </div>

            </ng-container>

        </div>
    </kendo-popup>
</div>

<!-- MENU TEMPLATES -->
<!-- USER -->
<ng-template #userMenuTemplate>
    <div class="appbar-button">
        <CDMS-common-button-control
            title="{{'title_Home' | langchange}}"
            class="dropdown-button"
            [button]="'icon'"
            [icon]="'home'"
            [size]="'medium'"
            type="primary"
            class="dropdown-button"
            [plane]="'horizontal'"
            (clickEvent)="goHome()">
        </CDMS-common-button-control>
    </div>
</ng-template>

<!-- ADMIN -->
<ng-template #adminMenuTemplate>
    <div class="appbar-button">
        <CDMS-common-button-control
            title="{{'title_Home' | langchange}}"
            class="dropdown-button"
            [button]="'icon'"
            [icon]="'home'"
            [size]="'medium'"
            type="primary"
            class="dropdown-button"
            [plane]="'horizontal'"
            (clickEvent)="goHome()">
        </CDMS-common-button-control>
    </div>
    <div class="appbar-button">
        <CDMS-common-button-control
            title="{{'title_SignOut' | langchange}}"
            class="dropdown-button"
            [button]="'icon'"
            [icon]="'logout'"
            [size]="'medium'"
            type="primary"
            [plane]="'horizontal'"
            (clickEvent)="signOut()">
        </CDMS-common-button-control>
    </div>
</ng-template>

<!-- ATTENDANT -->
<ng-template #attendantMenuTemplate>
    <div class="appbar-button">
        <CDMS-common-button-control
            title="{{'title_Home' | langchange}}"
            class="dropdown-button"
            [button]="'icon'"
            [icon]="'home'"
            [size]="'medium'"
            type="primary"
            class="dropdown-button"
            [plane]="'horizontal'"
            (clickEvent)="goHome()">
        </CDMS-common-button-control>
    </div>
    <div class="appbar-button">
        <CDMS-common-button-control
            title="{{'title_Profile' | langchange}}"
            class="dropdown-button"
            [button]="'icon'"
            [icon]="'user'"
            [size]="'medium'"
            type="primary"
            class="dropdown-button"
            [plane]="'horizontal'"
            (clickEvent)="goProfile()">
        </CDMS-common-button-control>
    </div>
    <div class="appbar-button">
        <CDMS-common-button-control
            title="{{'title_Language' | langchange}}"
            class="dropdown-button"
            [button]="'icon'"
            [icon]="'globe'"
            [size]="'medium'"
            type="primary"
            [plane]="'horizontal'"
            (clickEvent)="changeLanguage()">
        </CDMS-common-button-control>
    </div>
    <div class="appbar-button">
        <CDMS-common-button-control
            title="{{'title_SignOut' | langchange}}"
            class="dropdown-button"
            [button]="'icon'"
            [icon]="'logout'"
            [size]="'medium'"
            type="primary"
            [plane]="'horizontal'"
            (clickEvent)="signOut()">
        </CDMS-common-button-control>
    </div>
</ng-template>


<!-- HELP DROPDOWN -->
<div *ngIf="showHelp" class="dropdown-body-wrapper">
    <kendo-popup
        #helpPopup
        [anchor]="'helpButton'"
        [anchorAlign]="anchorAlignMenu"
        [popupAlign]="popupAlignMenu"
        animate="animation"
        (anchorViewportLeave)="toggleHelp()">
        <div class="dropdown-body dropdown-help">
            <p class="help-text">{{activePageDetail?.pageHelp}}</p>
        </div>
    </kendo-popup>
</div>