import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppConstants } from 'src/app/constants/app-constants';

import { WithinRadiusAttendantResponse } from 'src/app/services/providerdirectoryapi/providerdirectoryfunctionapi.services';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'CDMS-attendant-card-control',
    templateUrl: './attendant-card-control.component.html',
    styleUrls: ['./attendant-card-control.component.scss'],
})

export class AttendantCardControl implements OnInit {

    @Input() show = true;

    @Input()
    public set details(data: WithinRadiusAttendantResponse) {
        if (data?.schedule) {
            const days = data.schedule.split(',');
            const daysAbbr = [];
            days.forEach(x => daysAbbr.push(' ' + x.replace(' ', '').substring(0, 3)));
            const uniqueDays = [...new Set(daysAbbr)];
            let index = 0;
            if (this._lang === 'es') {
              uniqueDays.forEach(day => {
                switch (day) {
                  case ' Sun':
                    uniqueDays[index] = ' Dom';
                    break;
                  case ' Mon':
                    uniqueDays[index] = ' Lun';
                    break;
                  case ' Tue':
                    uniqueDays[index] = ' Mar';
                    break;
                  case ' Wed':
                    uniqueDays[index] = ' Mié';
                    break;
                  case ' Thu':
                    uniqueDays[index] = ' Jue';
                    break;
                  case ' Fri':
                    uniqueDays[index] = ' Vie';
                    break;
                  case ' Sat':
                    uniqueDays[index] = ' Sab';
                    break;
                }
                index++;
              });
            }
            data.schedule = uniqueDays.join();
        }
        this._details = data;
    }
    public get details() {
        return this._details;
    }

    @Output() clickEvent = new EventEmitter();

    private _details: WithinRadiusAttendantResponse;
    private _lang: string;

    constructor() {
      this._lang = AppConstants.selectedLanguage;
    }

    ngOnInit() {
    }

    public onClick() {
        this.clickEvent.emit(this.details.userId);
    }

}
