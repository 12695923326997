export const environment = {
  production: false,
  providerDirectoryApiHost: 'https://core-apim-shared-uat.azure-api.net/providerdirectory-api',
};

export const b2c_config = {
  directory : 'providerdirectory.onmicrosoft.com',
  authority : 'providerdirectory.b2clogin.com',
  signin_signup_policy : 'B2C_1A_SIGNUP_SIGNIN_UAT',
  signup_policy : 'B2C_1A_SIGNUP_UAT',
  change_signin_name_policy : 'B2C_1A_CHANGESIGNINNAME_UAT',
  password_reset_policy : 'B2C_1A_PASSWORDRESET_UAT',
  clientId: 'a628efe0-5725-4bbf-889a-81753b7905f8',
  appIdURI: 'api/uat'
};

export const configName = {
  name: 'site',
};

export const captcha = {
  siteKey: '6LdW_1ohAAAAAMP2apx6QvXIronK5FDd3JaUlNyD',
};
