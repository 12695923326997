import { Component, OnInit, HostListener, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators,} from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, takeUntil  } from 'rxjs';
import { PagerPosition, PagerType, PagerSettings, ListViewComponent } from '@progress/kendo-angular-listview';
import { Align, AnimationDirection, AnimationType, PopupAnimation, } from '@progress/kendo-angular-popup';

import { AppbarService } from '../../components/appbar/appbar-service';
import { AttendantSearchService } from '../../controllers/attendant/attendant-search.controller';
import { AttendantProfileService } from '../../controllers/attendant/attendant-profile.controller';
import { IWithinRadiusAttendantResponse } from '../../services/providerdirectoryapi/providerdirectoryfunctionapi.services';
import { Notification } from '../../common-components/services/common-notification.service';
import { ValidationFunctions } from '../../common-components/utilities/common-validation-functions';
import { radiusOptions, optionsDaysAbbr, AppConstants, optionsDaysAbbrES } from '../../constants/app-constants';
import { StorageConstants } from '../../constants/storage-constants';
import { AttendantSearch } from 'src/app/models/attendant/AttendantSearch.model';
import { INotification, NotificationSystemService } from 'src/app/common-components/controllers/common-notification-system.controller';

@Component({
  selector: 'app-attendant-search',
  templateUrl: 'attendant-search.page.html',
  styleUrls: ['attendant-search.page.scss',]
})
export class AttendantSearchPage implements OnInit, OnDestroy {

  @HostListener('document:click', ['$event'])
  public documentClick(event: KeyboardEvent): void {
    if (!this.contains(event.target)) {
      this.toggleMorePopup(false);
    }
  }

  @ViewChild('moreButton') public anchor: ElementRef;
  @ViewChild('morePopup', { read: ElementRef }) public popup: ElementRef;
  @ViewChild('listview', { read: ElementRef }) public listview: ListViewComponent;

  public searchResults: IWithinRadiusAttendantResponse[] = [];
  public searchData: AttendantSearch = new AttendantSearch();
  public searchForm: FormGroup;
  public showCaptcha = false;
  public loadSearch = false;
  public radiusOptions = radiusOptions;
  public dayOptions = optionsDaysAbbr;
  public dayOptionsES = optionsDaysAbbrES;
  public pageSize = 5;
  public position: PagerPosition = 'bottom';
  public pageSizes = false;
  public info = true;
  public prevNext = true;
  public type: PagerType = 'numeric';
  public showMore = false;
  public anchorAlign: Align = { horizontal: 'center', vertical: 'bottom' };
  public popupAlign: Align = { horizontal: 'center', vertical: 'top' };
  public animationType: AnimationType = 'slide';
  public animationDirDown: AnimationDirection = 'down';
  public animationDirUp: AnimationDirection = 'up';
  public isSearched = false;
  public lang: string;

  private _animation: PopupAnimation = {
      type: this.animationType,
      direction: (this.showMore) ? this.animationDirUp : this.animationDirDown,
      duration: 300,
  };

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private appbarService: AppbarService,
    private attendantSearchService: AttendantSearchService,
    private attendantProfileService: AttendantProfileService,
    private router: Router,
    private notificationSystemService: NotificationSystemService,
  ) {

    this.attendantSearchService.getSearchParameters()
    .pipe(
      takeUntil(this._destroying$)
    )
    .subscribe((data) => {
      if (data !== undefined) {
        this.searchData = data;
        this.setForm();
      };
    });

    this.attendantSearchService.getLoad()
    .pipe(
      takeUntil(this._destroying$)
    )
    .subscribe((data) => {
      if (data !== undefined) {
        this.loadSearch = data;
      }
    });

    this.appbarService.getAdminTrigger()
    .pipe(
      takeUntil(this._destroying$)
    )
    .subscribe((func) => {
      if (func) {
        switch (func) {
          case 'refresh':
            this.languageRefresh();
            break;
        }
      }
    });

    this.attendantSearchService.fetchData()
    .pipe(
      takeUntil(this._destroying$)
    )
    .subscribe((data) => {
      if (data !== undefined) {
        const captchaSolved =  window.sessionStorage.getItem(StorageConstants.capass);
        if (captchaSolved === StorageConstants.pass || location.hostname === 'localhost') {
          this.searchResults = data;
        } else {
          this.showCaptcha = true;
        }
      }
    });
  }

  public get pagerSettings(): PagerSettings {
    return {
      position: this.position,
      pageSizeValues: this.pageSizes,
      info: this.info,
      previousNext: this.prevNext,
      type: this.type,
      buttonCount: 5,
    };
  }

  public get getAnimation(): boolean | PopupAnimation {
    return this._animation;
  }

  ngOnInit(): void {
    this.lang = AppConstants.selectedLanguage;
    this.setPage();
    this.setForm();
  }

  ionViewWillEnter(): void {
    this.setPage();
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  public setPage() {
    this.appbarService.setPage('search');
  }

  public setForm() {
    this.searchForm = new FormGroup({
      zipCode: new FormControl(this.searchData.zipCode, [Validators.required, ValidationFunctions.zipcodeValidation]),
      radius: new FormControl(this.searchData.radius, [Validators.required]),
      day: new FormControl(this.searchData.day, []),
      hours: new FormControl(this.searchData.hours, [ValidationFunctions.numberGreaterThanOrEqualtoZero]),
      size: new FormControl(this.pageSize, []),
    });
  }

  public get showPager(): boolean {
    return this.searchResults && this.searchResults.length > 0;
  }

  public search() {
    const captchaSolved =  window.sessionStorage.getItem(StorageConstants.capass);
    if (this.searchForm.valid && (captchaSolved === StorageConstants.pass || location.hostname === 'localhost')) {
      this.formToEntity();
      this.convertDay();
      this.attendantSearchService.search(this.searchData);
      this.isSearched = true;
    } else if ( captchaSolved !== StorageConstants.pass) {
      this.showCaptcha = true;
    }
  }

  private convertDay() {
    if (this.lang === 'es') {
      switch(this.searchData.day) {
        case 'Dom':
          this.searchData.day = 'Sun';
          return;
        case 'Lun':
          this.searchData.day = 'Mon';
          return;
        case 'Mar':
          this.searchData.day = 'Tue';
          return;
        case 'Mié':
          this.searchData.day = 'Wed';
          return;
        case 'Jue':
          this.searchData.day = 'Thu';
          return;
        case 'Vie':
          this.searchData.day = 'Fri';
          return;
        case 'Sab':
          this.searchData.day = 'Sat';
          return;
      }
    }
  }

  public toggleMorePopup(status?: boolean) {
    if (typeof status !== 'undefined') { this.showMore = status; }
    else { this.showMore = !this.showMore; }
  }

  private contains(target: EventTarget): boolean {
    return (
      this.anchor.nativeElement.contains(target) ||
      (this.popup ? this.popup.nativeElement.contains(target) : false)
    );
  }

  public clearFilters() {
    Object.keys(this.searchForm.controls).forEach(key => {
      this.searchForm.controls[key].reset();
      this.searchData[key] = null;
    });
  }

  public formToEntity() {
    Object.keys(this.searchForm.controls).forEach(key => {
      if (this.searchForm.controls[key].value?.value) {
        this.searchData[key] = this.searchForm.controls[key].value.value;
      } else {
        this.searchData[key] = this.searchForm.controls[key].value;
      }
    });
  }

  public goProfile(id: string){
    this.attendantProfileService.clear();
    this.attendantProfileService.search(id);
    this.attendantProfileService.setSearchParameters(id);
    this.router.navigate(['/Attendant']);
  }

  public captchaResponse(data: boolean, eventData) {
    if (data === true) {
      window.sessionStorage.setItem(StorageConstants.capass, StorageConstants.pass);
      this.showCaptcha = false;
      this.search();
    } else {
      const notification: INotification = {type:'error', content: 'Captcha must be passed to search'};
      this.notificationSystemService.setNotification(notification);
    }
  }

  private languageRefresh() {
    window.location.reload();
  }

}
