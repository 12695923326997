<div kendoTooltip [showAfter]="1250" class="button-wrapper">
    <button 
        kendoButton
        #infoButton
        class="common-info-slideout common-info-slideout-{{iconSize}} common-info-slideout-{{type}}"
        icon="question"
        title="{{title ?? 'Additional Information'}}"
        type="button"
        (click)="toggle()">
    </button>
</div>

<div *ngIf="show" class="common-slideout-wrapper">
    <div class="common-slideout common-slideout-{{slideoutSize}} common-info-slideout-{{type}}">
        <kendo-popup
            #infoPopup
            [anchor]="infoButton"
            [anchorAlign]="anchorAlign"
            [popupAlign]="popupAlign"
            [animate]="getAnimation"
            (anchorViewportLeave)="toggle()"
            (click)="toggle()">
            <div class="content">
                <p *ngIf="title" class="Txt5">{{title}}</p>
                <p class="Txt6">{{text}}</p>
            </div>
    </kendo-popup>
    </div>
</div>
