import { Component, OnInit, OnDestroy} from '@angular/core';
import { FormGroup, FormControl, Validators,} from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, takeUntil  } from 'rxjs';

import { AttendantSearch } from '../../models/attendant/AttendantSearch.model';
import { radiusOptions } from '../../constants/app-constants';
import { AppbarService } from '../../components/appbar/appbar-service';
import { AttendantSearchService } from '../../controllers/attendant/attendant-search.controller';
import { UserService } from 'src/app/controllers/user/user.controller';
import { ValidationFunctions } from '../../common-components/utilities/common-validation-functions';
import { Roles } from 'src/app/constants/app-types';
import { INotification, NotificationSystemService } from 'src/app/common-components/controllers/common-notification-system.controller';
import { StorageConstants } from 'src/app/constants/storage-constants';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage implements OnInit, OnDestroy  {

  public searchData: AttendantSearch = new AttendantSearch();
  public searchForm: FormGroup;
  public radiusOptions = radiusOptions;
  public role: Roles;
  public colorado = this.configService.readConfig().colorado;
  public showCaptcha = false;
  public showTestButton = false;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private notificationSystemService: NotificationSystemService,
    private appbarService: AppbarService,
    private attendantSearchService: AttendantSearchService,
    private userService: UserService,
    private router: Router,
    private configService: ConfigService,
  ) {

    this.userService.getRoleHighest()
    .pipe(
      takeUntil(this._destroying$)
    )
    .subscribe((data) => {
      if (data !== undefined) {
        this.role = data;
      }
    });
  }

  ngOnInit(): void {
    this.setPage();
    this.setForm();
  }

  ionViewWillEnter(): void {
    this.setPage();
  }

  public setPage() {
    this.appbarService.setPage('home');
  }

  public setForm() {
    this.searchForm = new FormGroup({
      zipCode: new FormControl(this.searchData.zipCode, [Validators.required, ValidationFunctions.zipcodeValidation]),
      radius: new FormControl(this.searchData.radius, [Validators.required]),
    });
  }

  public formToEntity() {
    Object.keys(this.searchForm.controls).forEach(key => {
      if (this.searchForm.controls[key].value?.value) {
        this.searchData[key] = this.searchForm.controls[key].value.value;
      } else {
        this.searchData[key] = this.searchForm.controls[key].value;
      }
    });
  }

  public goAdminLogin(): void {
    this.router.navigate(['/Admin/Attendants']);
  }

  public goSearch(): void {
    const captchaSolved =  window.sessionStorage.getItem(StorageConstants.capass);
    if (this.searchForm.valid && (captchaSolved === StorageConstants.pass || location.hostname === 'localhost')) {
      this.formToEntity();
      this.attendantSearchService.setSearchParameters(this.searchData);
      this.attendantSearchService.search();
      this.router.navigate(['/Search']);
    } else if ( captchaSolved !== StorageConstants.pass) {
      this.showCaptcha = true;
    }
  }

  public goRegister(): void {
      this.router.navigate(['/Register']);
  }

  public goRequirements(): void {
    this.router.navigate(['/Requirements']);
  }

  public captchaResponse(data: boolean, eventData) {
    if (data === true) {
      window.sessionStorage.setItem(StorageConstants.capass, StorageConstants.pass);
      this.showCaptcha = false;
      this.goSearch();
    } else {
      const notification: INotification = {type:'error', content: 'Captcha must be passed to search'};
      this.notificationSystemService.setNotification(notification);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
