import { OnInit, Component, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AttendantProfileService } from 'src/app/controllers/attendant/attendant-profile.controller';

@Component({
  selector: 'attendant-reaffirm',
  templateUrl: 'attendant-reaffirm.page.html',
  styleUrls: ['attendant-reaffirm.page.scss'],
})

export class AttendantReaffirmPage implements OnInit, AfterViewInit, OnDestroy {

  public id: string;
  public isSuccess: boolean;
  public load: boolean;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private attendantProfileService: AttendantProfileService,
    ) {

      this.attendantProfileService.getSuccess()
      .pipe(
        takeUntil(this._destroying$)
      )
      .subscribe((data) => {
        if (data !== undefined) {
          this.isSuccess = data;
        }
      });

      this.attendantProfileService.getLoad()
      .pipe(
        takeUntil(this._destroying$)
      )
      .subscribe((data) => {
        if (data !== undefined) {
          this.load = data;
        }
      });
    }

  ngOnInit()  {
    this.id = this.route.snapshot.paramMap.get('id');
  }

  ngAfterViewInit() {
    if (this.id) {
      this.attendantProfileService.affirm(this.id);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
