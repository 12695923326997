import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ReviewRequest, ProviderDirectoryFunctionService, ProfileStatus, Attendant }
  from 'src/app/services/providerdirectoryapi/providerdirectoryfunctionapi.services';
import { NotificationSystemService, INotification } from 'src/app/common-components/controllers/common-notification-system.controller';


@Injectable({
  providedIn: 'root',
})

export class AdminAttendantsService implements OnDestroy {
  private _entity: BehaviorSubject<ReviewRequest[]> = new BehaviorSubject<ReviewRequest[]>(
    undefined
  );
  private _parameters: BehaviorSubject<any> = new BehaviorSubject<any>(
    undefined
  );
  private _load: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private _attendantParameters: BehaviorSubject<any> = new BehaviorSubject<any>(
    undefined
  );
  private _attendants: BehaviorSubject<Attendant[]> = new BehaviorSubject<Attendant[]>(
    undefined
  );

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private notificationSystemService: NotificationSystemService,
    private providerDirectoryFunctionService: ProviderDirectoryFunctionService,
  ) { }

  public search(data?: any): void {
    this._load.next(true);
    this.providerDirectoryFunctionService.getAllReviewRequest()
      .pipe(
        take(1),
        takeUntil(this._destroying$)
      )
      .subscribe({
        next: (res) => {
          if (res) {
            if (!res.isSuccess) {
              const notification: INotification = { type: 'error', content: res.message };
              this.notificationSystemService.setNotification(notification);
            } else {
              this._entity.next(res.data);
            }
          }
        },
        error: error => {
          if (error.message) {
            const notification: INotification = { type: 'error', content: error.message };
            this.notificationSystemService.setNotification(notification);
          }
        },
        complete: () => {
          this._load.next(false);
        },
      });
  }

  public searchAttendants(data?: any): void {
    if (data !== undefined) {
      this._attendantParameters.next(data);
    } else {
      data = this._attendantParameters.getValue();
    }
    if (data !== undefined) {
      Object.keys(data).forEach(key => {
        if (data[key] === null) {
          delete data[key];
        }
      });
    }
    this._load.next(true);
    if (data['status']?.value !== undefined) {
      data['status'] = ProfileStatus[ProfileStatus[data['status']['value']]];
    }
    this.providerDirectoryFunctionService.getBySearchCriteria(
      data['userId'], data['email'], data['firstName'], data['lastName'], data['state']
      , data['zipcode'], data['status'], data['certification'], data['certification']
    )
      .pipe(
        take(1),
        takeUntil(this._destroying$)
      )
      .subscribe({
        next: (res) => {
          if (res) {
            if (!res.isSuccess) {
              const notification: INotification = { type: 'error', content: res.message };
              this.notificationSystemService.setNotification(notification);
            } else {
              this._attendants.next(res.data);
            }
          }
        },
        error: error => {
          if (error.message) {
            const notification: INotification = { type: 'error', content: error.message };
            this.notificationSystemService.setNotification(notification);
          }
        },
        complete: () => {
          this._load.next(false);
        },
      });
  }

  public fetchData(bool = true): Observable<ReviewRequest[]> {
    if (this._parameters.getValue() !== undefined && bool) {
      this.search();
    }
    return this._entity;
  }

  public fetchAttendants(bool = true): Observable<Attendant[]> {
    if (this._attendants.getValue() !== undefined && bool) {
      this.searchAttendants(this._attendantParameters);
    }
    return this._attendants;
  }

  public getLoad(): Observable<boolean> {
    return this._load;
  }

  public clear(): void {
    this._entity.next(null);
    this._parameters.next(null);
  }

  public clearEntity(): void {
    this._entity.next(null);
  }

  public clearParamters(): void {
    this._parameters.next(null);
  }

  public clearAttendants(): void {
    this._attendants.next(null);
    this._attendantParameters.next(null);
  }

  public clearLoad(): void {
    this._load.next(null);
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
